import ENGLISH_TRANSLATION from '../translate/lang/en';
import MALAY_TRANSLATION from '../translate/lang/ms';
import { LOCALE_SELECTED } from '../actions/actionTypes';

const initialState = {
  locale: ENGLISH_TRANSLATION.locale,
  messages: ENGLISH_TRANSLATION.messages,
};

/**
 * @description Checks which locale has been set by the user.
 * @param {Object} state - Sets the initial locale and messages for the application.
 * @param {Object} action - Action object which contains data passed from the action.
 * @returns {Object} Return the corresponding locale and message based on locale set.
 */
const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCALE_SELECTED:
      switch (action.locale) {
        case 'ms':
          return {
            ...initialState,
            locale: MALAY_TRANSLATION.locale,
            messages: MALAY_TRANSLATION.messages,
          };
        default:
          return {
            ...initialState,
            locale: ENGLISH_TRANSLATION.locale,
            messages: ENGLISH_TRANSLATION.messages,
          };
      }
    default:
      return state;
  }
};
export default localeReducer;
