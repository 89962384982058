/* eslint-disable max-lines-per-function */
/* eslint-disable no-return-await */
/* eslint-disable complexity */
import {
  PublicClientApplication,
  LogLevel,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import config from '@core/base/config';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
let interactionError = false;
const loginRequest = {
  scopes: config.scopeInfo.security,
  forceRefresh: false,
};
const errorCodes = {
  INTERACTION: 'interaction_in_progress',
};
const tokenRequest = {
  scopes: config.scopeInfo.security,
  prompt: 'login',
};
/**
 * @description - Set username for further msal calls.
 * @returns {Object} - Config.
 */
function getConfig() {
  const { host } = window.location;
  const PROD_URL = 'dove.petronas.com';
  let key = '';
  if (host.includes('localhost')) {
    key = process.env.REACT_APP_ENV || 'primedev';
  } else {
    key = host.replace(PROD_URL, '').replace('.', '') || 'prod';
  }
  return config.openIDConfig[key];
}

const doveConfig = getConfig();
const msalConfig = {
  auth: {
    clientId: doveConfig.clientID,
    authority: doveConfig.authority,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: doveConfig.cacheLocation, // This configures where your cache will be stored
    storeAuthStateInCookie: isIE || isEdge, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line complexity
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
          case LogLevel.Info:
          case LogLevel.Verbose:
          case LogLevel.Warning:
          default:
        }
      },
    },
  },
};
// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new PublicClientApplication(msalConfig);

/**
 * @description - Set username for further msal calls.
 * @param {string} username - The type of data that will be fetched.
 */
function setUsername(username) {
  localStorage.setItem('MSALUsername', username);
}

/**
 * @description - Set username for further msal calls.
 * @returns {string} Username - The type of data that will be fetched.
 */
function getUsername() {
  return localStorage.getItem('MSALUsername');
}

/**
 * @description - Remove username before logout call.
 */
function removeUsername() {
  localStorage.removeItem('MSALUsername');
}

/**
 * @description - Gets access token from session storage.
 * @param {string} resp - The type of data that will be fetched.
 * @returns {undefined} - Returns accesstoken.
 */
function handleResponse(resp) {
  if (resp !== null) {
    setUsername(resp.account.username);
    return 1;
  }
  const accounts = myMSALObj.getAllAccounts();
  if (!getUsername() && accounts && accounts.length > 0) {
    setUsername(accounts[0]);
  }
  return 2;
}

/**
 * @description - Gets access token from session storage.
 * @returns {undefined} - Returns accesstoken.
 */
export async function listenForTokenRequest() {
  return await myMSALObj
    .handleRedirectPromise()
    .then(handleResponse)
    .catch(err => err);
}

/**
 * @description - Gets access token from session storage.
 * @returns {undefined} - Returns accesstoken.
 */
export function msalSignIn() {
  return myMSALObj.loginRedirect(loginRequest);
}

/**
 * @description - Gets logged in accounts info.
 * @returns {undefined} - Returns account object.
 */
export function getAccount() {
  return myMSALObj.getAccountByUsername(getUsername());
}

/**
 * @description - Gets access token from session storage.
 * @returns {undefined} - Returns accesstoken.
 */
export function signOut() {
  const logoutRequest = {
    account: getAccount(),
  };
  removeUsername();
  myMSALObj.logout(logoutRequest);
}

/**
 * @description - Check for ongoing popup and wait.
 * @returns {undefined} - Returns accesstoken.
 */
// async function waitInteraction() {
//   return await new Promise(resolve => {
//     const interval = setInterval(() => {
//       if (!interactionError) {
//         resolve(true);
//         clearInterval(interval);
//       }
//     }, 10);
//   });
// }

/**
 * @description - Gets access token from session storage.
 * @param {string} scope - Scope value to be attached with request.
 * @returns {undefined} - Returns accesstoken.
 */
export async function getTokenSilent(scope = null) {
  const request = JSON.parse(JSON.stringify(tokenRequest));
  // eslint-disable-next-line prefer-destructuring
  request.account = getAccount();
  if (scope) {
    request.scopes = scope;
  }
  // await waitInteraction();
  return await myMSALObj.acquireTokenSilent(request).catch(async error => {
    if (error instanceof InteractionRequiredAuthError && !interactionError) {
      interactionError = true;
      // fallback to interaction when silent call fails
      return (
        myMSALObj
          .acquireTokenPopup(tokenRequest)
          .then(res => {
            interactionError = false;
            window.location.reload();
            return res;
          })
          // eslint-disable-next-line consistent-return
          .catch(popupError => {
            interactionError = true;
            try {
              const parsedError = JSON.parse(JSON.stringify(popupError));
              if (parsedError.errorCode !== errorCodes.INTERACTION) {
                interactionError = false;
                return { error: 'login failed' };
              }
            } catch (e) {
              return e;
            }
          })
      );
    }
    return { error: 'Token Validation failed' };
  });
}
