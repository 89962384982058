import FeatureFlag from '@core/utils/featureFlag';
/**
 * @description - Set user details function.
 * @param {Object} user - User.
 * @returns {number} - One if given user is valid.
 */
const setUser = user => {
  try {
    localStorage.setItem('role', JSON.stringify(user.personaRule));
    return 1;
  } catch (error) {
    return 0;
  }
};

/**
 * @description - Function to get default user persona.
 * @param {Object} user - User details.
 * @param {boolean} singlePersona - SIngle / multiple persona login.
 * @returns {Object} - Default Persona.
 */
const getDefaultPersona = (user, singlePersona = false) => {
  const defaultPersona = {};
  user.map(u => {
    if (u.defaultPersona === true || singlePersona) {
      defaultPersona.persona = u.persona;
      defaultPersona.personaId = u.personaId;
    }
    return defaultPersona;
  });
  return defaultPersona;
};

/**
 * @description - Function to convert single persona data to multi persona format.
 * @param {Object} res - Single persona response data.
 * @returns {Array} - Persona rules.
 */
const getPersonaRule = res => {
  const personaRule = [];
  const roleData = {
    persona: res.persona,
    personaId: res.personaId,
    defaultPersona: true,
  };
  personaRule.push(roleData);
  return personaRule;
};

/**
 * @description - Function to sort the persona array.
 * @param {Array} personaRule - Unsorted persona array.
 * @returns {Array} - Sorted Persona array.
 */
const sortPersona = personaRule => {
  personaRule.sort((a, b) => a.persona.localeCompare(b.persona));
  const index = personaRule.findIndex(el => el.defaultPersona === true);
  const defaultPersona = personaRule[index];
  personaRule.splice(index, 1);
  personaRule.unshift(defaultPersona);
  return personaRule;
};

/**
 * @description - Function to restructure the api response.
 * @param {Object} res - API response data.
 */
const getUserData = res => {
  const isMultipersona = FeatureFlag.MULTIPERSONA;
  let tempData = {};
  if (!isMultipersona) {
    const personaRule = getPersonaRule(res);
    tempData = {
      ...res,
      personaRule,
      isValidRole: setUser({ personaRule }),
      defaultPersona: getDefaultPersona([res], true),
    };
  } else {
    res.personaRule = sortPersona(res.personaRule);
    tempData = {
      ...res,
      defaultPersona: getDefaultPersona(res.personaRule),
      isValidRole: setUser(res),
    };
  }

  return tempData;
};

export default getUserData;
