import mixins from '@styles/common/mixins';
import fontmixins from '@styles/fontmixins';

const styles = {
  fontstyle: {
    ...fontmixins.OpenSansSemibold(),
    ...mixins.pxtorem(13),
  },
};

export { styles as default };
