import DateFormatter from '@core/utils/dateFormatter';
import config from '@core/base/config';

/**
 * @description - Common validator class.
 */
class Validate {
  /**
   * @description - Method to handle undefined variable with defalt value.
   * @param {*} val - Value to be checked.
   * @param {string} def - Value to be returned in case of undefined val.
   * @returns {*} - Val  / def.
   */
  static isExist = (val, def) => (val !== undefined && val !== null ? val : def);

  /**
   * @description - Method to handle undefined variable with defalt value.
   * @param {*} val - Value to be checked.
   * @param {string} def - Value to be returned in case of undefined val.
   * @returns {*} - Val  / def.
   */
  static isValueEmpty = (val, def) => (val && val !== '' ? val : def);

  /**
   * @description - Method to handle undefined variable with defalt / custom value.
   * @param {*} val - Value to be checked.
   * @param {string} def - Value to be returned in case of undefined val.
   * @param {string} custom - Custom Value to be returned in case of val.
   * @returns {*} - Custom / def.
   */
  static isExistWithCustomReturn = (val, def, custom) => {
    if (Validate.ifBoth(val !== undefined, val !== null) && val !== '') {
      return Validate.isExist(custom, def);
    }
    return def;
  };

  /**
   * @description - Method to handle If condition with OR.
   * @param {*} a - Value to be checked.
   * @param {*} b - Value to be checked.
   * @returns {boolean} - True / False.
   */
  static ifAny = (a, b) => a || b;

  /**
   * @description - Method to handle If condition with AND.
   * @param {*} a - Value to be checked.
   * @param {*} b - Value to be checked.
   * @returns {boolean} - True / False.
   */
  static ifBoth = (a, b) => a && b;

  /**
   * @description - Method to handle If condition with AND.
   * @param {*} a - Value to be checked.
   * @param {*} b - Value to be checked.
   * @param {*} c - Value to be checked.
   * @returns {boolean} - True / False.
   */
  static ifThree = (a, b, c) => !!(Validate.ifBoth(a, b) && c);

  /**
   * @description - Method to handle If condition with AND.
   * @param {*} a - Value to be checked.
   * @param {*} b - Value to be checked.
   * @param {*} c - Value to be checked.
   * @returns {boolean} - True / False.
   */
  static ifAnyThree = (a, b, c) => !!(Validate.ifAny(a, b) || c);

  /**
   * @description - Method to handle If condition.
   * @param {*} val - Value to be checked.
   * @returns {boolean} - True / False.
   */
  static ifData = val => val || false;

  /**
   * @description - Method to handle Array with data or def.
   * @param {Array} data - Array to be checked.
   * @param {string} def - Value to be returned in case if array is undefined.
   * @returns {*} - Data / def.
   */
  static isDataExist = (data, def) => (data && data.length > 0 ? data : def);

  /**
   * @description - Method to check Array has data.
   * @param {Array} data - Array to be checked.
   * @returns {boolean} - True / False.
   */
  static hasData = data => data && data.length > 0;

  /**
   * @description - Method to handle Object has list of value under given key.
   * @param {Array} data - Array to be checked.
   * @param {string} key - Key to be checked.
   * @returns {boolean} - True / False.
   */
  static hasChildData = (data, key = 'data') => data && data[key] && data[key].length > 0;

  /**
   * @description - Method to handle Object has value under given key.
   * @param {Object} data - Data to be checked.
   * @param {string} key - Key to be checked.
   * @returns {boolean} - True / False.
   */
  static hasChild = (data, key = 'data') => data && data[key];

  /**
   * @description - Method to handle value is empty.
   * @param {Array} val - Value to be checked.
   * @param {string} def - Value to be returned in case of empty val.
   * @returns {*} - Val / def.
   */
  static isEmpty = (val, def) => (val !== '' && val !== 0 ? val : def);

  /**
   * @description - Method to handle date.
   * @param {string} date - Value to be checked.
   * @returns {boolean} - True / False.
   */
  static isToday = date => {
    const formattedDate = DateFormatter.format(
      date,
      {
        format: config.dateSubFormat,
      },
      false,
    );
    const currentDate = DateFormatter.format(
      new Date(),
      {
        format: config.dateSubFormat,
      },
      false,
    );
    return formattedDate === currentDate;
  };

  /**
   * @description - Checks time value exists.
   * @param {Object} data - Input data.
   * @param {string} key - Key Value.
   * @param {Date} def - Default date value.
   * @returns {Date} - Last updated date value.
   */
  static hasTimeValue = (data, key, def) => (data && data[key] ? data[key] : def);

  /**
   * @description - Check value exists or not.
   * @param {*} val - Input value.
   * @returns {boolean} - Checked value.
   */
  static checkValueExists = val => val !== undefined && val !== null && val !== '';

  /**
   * @description - Check value undefined/null.
   * @param {*} val - Input value.
   * @returns {boolean} - Checked value.
   */
  static checkValueDefined = val => val !== undefined && val !== null;

  /**
   * @description - Check value exists or not.
   * @param {*} val - Input value.
   * @param {*} data - Input value.
   * @param {*} def - Input value.
   * @returns {boolean} - Checked value.
   */
  static isTrue = (val, data, def) => (val ? data : def);

  /**
   * @description - Check 2 value is equal of type and value.
   * @param {*} a - First item.
   * @param {*} b - Second item.
   * @returns {*} - Return Boolean.
   */
  static isEqual = (a, b) => a === b;

  /**
   * @description - Checks whether object is empty or not.
   * @param {Object} data - Input object.
   * @returns {boolean} - Checked result.
   */
  static checkEmptyObject = data => data && Object.keys(data).length > 0;
}

export default Validate;
