import fonts from '@styles/common/fonts.scss';

const mixins = {
  /**
   * @description - Function to define border radius.
   * @param {string} radius - Radius value.
   * @returns {Object} - Returns the border radius object.
   */
  borderRadus(radius) {
    return {
      'border-radius': radius,
      '-webkit-border-radius': radius,
      '-moz-border-radius': radius,
      '-ms-border-radius': radius,
    };
  },

  /**
   * @description - Function to define the grid area.
   * @param {number} rowStart - Row start value.
   * @param {number} colStart - Col start value.
   * @param {number} rowEnd - Row end value.
   * @param {number} colEnd - Col end value.
   * @returns {Object} - Returns the grid area object.
   */
  gridArea(rowStart, colStart, rowEnd, colEnd) {
    return {
      '-ms-grid-row': rowStart,
      '-ms-grid-row-span': rowEnd - rowStart,
      '-ms-grid-column': colStart,
      '-ms-grid-column-span': colEnd - colStart,
    };
  },

  /**
   * @description - Function to define OpenSansLight font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansLight() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 300,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansLightItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansLightItalic() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 300,
      'font-style': 'italic',
    };
  },

  /**
   * @description - Function to define OpenSans font.
   * @returns {Object} - Returns the font object.
   */
  OpenSans() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 400,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansItalic() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 400,
      'font-style': 'italic',
    };
  },

  /**
   * @description - Function to define OpenSansSemiBold font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansSemiBold() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 600,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansSemiBoldItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansSemiBoldItalic() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 600,
      'font-style': 'italic',
    };
  },

  /**
   * @description - Function to define OpenSansBold font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansBold() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 700,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansBoldItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansBoldItalic() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 700,
      'font-style': 'italic',
    };
  },

  /**
   * @description - Function to define OpenSansExtraBold font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansExtraBold() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 800,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansExtraBoldItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansExtraBoldItalic() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 800,
      'font-style': 'italic',
    };
  },

  /**
   * @description - Function which converts pixel to rem.
   * @param {number} pixelvalue - Pixel value.
   * @returns {Object} - Returns font object.
   */
  pxtorem(pixelvalue) {
    const remvalue = pixelvalue / fonts.baseFontSize;
    return {
      'font-size': `${remvalue}rem`,
    };
  },
};

export default mixins;
