/* eslint dove-internal/no-allow-import: 0 */
import moment from 'moment';
import config from '@core/base/config';

/**
 * @description - Format Date.
 * @param {Date} newDate - Date.
 * @param {Object} options - Option to format date.
 * @returns {Date} - Formated Date.
 */
const formatDate = (newDate, options) => {
  if (newDate && options.format) {
    return newDate.format(options.format);
  }
  return newDate;
};

/**
 * @description - Add date.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency and value to add.
 * @returns {Date} - Updated date.
 */
const add = (newDate, options) => {
  if (newDate && options.add) {
    return newDate.add(options.add.value, options.add.frequency);
  }
  return newDate;
};

/**
 * @description - Subtract date.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency and value to subtract.
 * @returns {Date} - Updated date.
 */
const subtract = (newDate, options) => {
  if (newDate && options.subtract) {
    return newDate.subtract(options.subtract.value, options.subtract.frequency);
  }
  return newDate;
};

/**
 * @description - End of date.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency.
 * @returns {Date} - Updated date.
 */
const endOf = (newDate, options) => {
  if (newDate && options.endOf) {
    return newDate.endOf(options.endOf.frequency);
  }
  return newDate;
};

/**
 * @description - Date in utc format.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency.
 * @returns {Date} - Updated date.
 */
const utc = (newDate, options) => {
  if (newDate && options.utc) {
    return newDate.utc(options.utc.frequency);
  }
  return newDate;
};

/**
 * @description - Difference.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency.
 * @returns {Date} - Updated date.
 */
const diff = (newDate, options) => {
  if (newDate && options.diff) {
    return newDate.diff(options.diff.frequency);
  }
  return newDate;
};

/**
 * @description - Start OF month.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency.
 * @returns {Date} - Updated date.
 */
const startOf = (newDate, options) => {
  if (newDate && options.startOf) {
    return newDate.startOf(options.startOf.frequency);
  }
  return newDate;
};

/**
 * @description - Difference.
 * @param {Date} newDate - Date.
 * @param {Object} options - Frequency.
 * @returns {Date} - Updated date.
 */
const isBetween = (newDate, options) => {
  if (newDate && options.isBetween) {
    return newDate.isBetween(options.isBetween.lowerLimit, options.isBetween.upperLimit);
  }
  return newDate;
};

/**
 * @description - Function to get total days in month.
 * @param {string} yearMonth - Year and month.
 * @returns {string} - Days in month.
 */
const totalDaysInMonth = yearMonth => moment(yearMonth, config.yearMonth).daysInMonth();

/**
 * @description - Function to get check date is greter than othe.
 * @param {string} date - Date to be checked.
 * @param {string} max - Max date.
 * @returns {boolean} - True / False.
 */
const isGReaterThan = (date, max) => moment(date).isAfter(max);

/**
 * @description - Function to get check date is greter than othe.
 * @param {string} date - Date to be checked.
 * @param {string} min - Max date.
 * @returns {boolean} - True / False.
 */
const isLessThan = (date, min) => moment(date).isBefore(min);

const DateFormatter = {
  format: (date = new Date(), options = {}, localizationRequired = true) => {
    let newDate = moment(date);
    if (localizationRequired) newDate = newDate.utc(date).local();
    newDate = add(newDate, options);
    newDate = subtract(newDate, options);
    newDate = endOf(newDate, options);
    newDate = startOf(newDate, options);
    newDate = diff(newDate, options);
    newDate = utc(newDate, options);
    newDate = formatDate(newDate, options);
    newDate = isBetween(newDate, options);
    return newDate;
  },

  getMonths: (short = false) => {
    if (short) {
      moment.localeData('en').months();
      return moment.localeData('en').monthsShort();
    }
    return moment.months();
  },

  totalDaysInMonth: yearMonth => totalDaysInMonth(yearMonth),

  momentDate: (date = new Date()) => moment(date),

  isGReaterThan: (date, max = new Date()) => isGReaterThan(date, max),

  isLessThan: (date, min = new Date()) => isLessThan(date, min),

  dateFromString: dateStr => moment(dateStr).toDate(),

  yearStart: options =>
    moment()
      .startOf('year')
      .format(options),

  getLastDateOfMonth: (year, month) => {
    const startDate = moment([year, month - 1]);
    const endDate = moment(startDate).endOf('month');
    return endDate.format('DD');
  },

  getDayNumber: ymddate => {
    const date = moment(ymddate); // YYYY-MM-DD
    const dow = date.day();
    return dow;
  },

  getDiff: (a, b) => moment(a).diff(moment(b), 'days'),

  getDaysInaMonth: curMonth => moment(curMonth, config.yearMonth).daysInMonth(),
};
export default DateFormatter;
