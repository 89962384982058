/*
        Name : Generic Button Component
*/
import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import RoundedBtnNormal from '@core/components/Typography/RoundedBtnNormal';
import styles from './button_style';

/**
 * @description - Button component.
 */
class Button extends React.PureComponent {
  /**
   * @description - Button style.
   */
  getButtonBackgroundStyle = () => {
    const { textAlign, bgColor, margin, txtColor, border, boxShdw } = this.props;
    const {
      height,
      cursor,
      fontSize,
      radius,
      width,
      padding,
      overrideStyles,
      alignItems,
    } = this.props;
    return {
      backgroundColor: bgColor,
      color: txtColor,
      margin,
      border,
      boxShadow: boxShdw,
      fontSize,
      borderRadius: radius,
      width,
      height,
      textAlign,
      cursor,
      padding,
      alignItems,
      ...overrideStyles,
    };
  };

  /**
   * @description - Function to render button component.
   */
  render() {
    const { classes, onClick, onKeyDown, children, fontSize } = this.props;
    const buttonBackgroundStyle = this.getButtonBackgroundStyle();
    return (
      <div
        role="button"
        tabIndex="0"
        style={buttonBackgroundStyle}
        className={classes.customButton}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <RoundedBtnNormal fontSize={fontSize}>{children}</RoundedBtnNormal>
      </div>
    );
  }
}
Button.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  bgColor: PropTypes.string,
  txtColor: PropTypes.string,
  margin: PropTypes.string,
  border: PropTypes.string,
  boxShdw: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  fontSize: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  textAlign: PropTypes.string,
  radius: PropTypes.string,
  cursor: PropTypes.string,
  padding: PropTypes.string,
  alignItems: PropTypes.string,
  overrideStyles: PropTypes.shape({}),
};
Button.defaultProps = {
  bgColor: '',
  txtColor: '',
  margin: '',
  border: '',
  boxShdw: '',
  children: '',
  onClick: null,
  onKeyDown: null,
  fontSize: '',
  width: '',
  height: '',
  textAlign: '',
  radius: '',
  cursor: '',
  padding: '',
  alignItems: '',
  overrideStyles: {},
};

export default injectSheet(styles)(Button);
