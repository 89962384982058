import valueMap from '../flagValueMapper';

// valuemap(uat, prod, local, qaint, dev)

const config = {
  berthOccupancy: {
    disableUpcoming: valueMap(false, false, false, false, false),
  },
};
export default config;
