/* eslint complexity: ["error", 4] */
import { SHOW_TOAST_MSG, CLOSE_TOAST_MSG, TOAST_MSG_FAILURE } from '../actions/actionTypes';

const initialState = {
  loading: true,
  error: false,
  data: '',
  errormsg: '',
  closeToaster: false,
};

/* complexity is 4 because we have 4 case conditions */
/**
 * @description - Toaster Reducer.
 * @param {Object} state - State.
 * @param {Object} action - Action.
 * @returns {Object} - Returns updated state.
 */
const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST_MSG:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload,
        error: false,
        closeToaster: true,
      });
    case CLOSE_TOAST_MSG:
      return Object.assign({}, state, {
        data: '',
        closeToaster: false,
      });
    case TOAST_MSG_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload,
        error: true,
        closeToaster: true,
      });
    default:
      return state;
  }
};

export default toastReducer;
