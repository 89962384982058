import colors from '@styles/common/color_variables.scss';

const styles = {
  loadingContainer: {
    display: 'flex',
    margin: '10px 0',
  },
  '@keyframes loadingDelay': {
    from: { background: colors.loader_bg_gradient_variant_1 },
    to: { background: colors.loader_bg_gradient_variant_2 },
  },
  dot: {
    width: '10px',
    height: '10px',
    margin: '2px',
    borderRadius: '100%',
    backgroundColor: colors.loader_bg_gradient_variant_1,
    WebkitAnimation: 'loadingDelay 1.4s infinite ease-in-out both',
    animation: 'loadingDelay 1.4s infinite ease-in-out both',
    animationName: `loadingDelay`,
  },
  dot1: {
    WebkitAnimationDelay: '-0.32s',
    animationDelay: '-0.32s',
  },
  dot2: {
    WebkitAnimationDelay: '-0.16s',
    animationDelay: '-0.16s',
  },
};

export default styles;
