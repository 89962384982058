/* eslint complexity: ["error", 4] */
import { MULTISELECT } from '../actions/actionTypes';

const initialState = {
  data: [],
};
/**
 * @description - Toaster Reducer.
 * @param {Object} state - State.
 * @param {Object} action - Action.
 * @returns {Object} - Returns updated state.
 */
const multiSelectDropdownReducer = (state = initialState, action) => {
  switch (action.type) {
    case MULTISELECT.UPDATE:
      return Object.assign({}, state, {
        data: action.payload,
      });
    case MULTISELECT.CLEAR:
      return Object.assign({}, state, {
        data: [],
      });
    default:
      return state;
  }
};

export default multiSelectDropdownReducer;
