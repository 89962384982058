import mixins from '@styles/common/mixins';
import fontmixins from '@styles/fontmixins';

const styles = {
  customButton: {
    display: 'inline-flex',
    borderRadius: mixins.borderRadius,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '15px',
    paddingRight: '15px',
    // fontFamily: mixins.OpenSansSemiBold,
    cursor: 'pointer',
    outline: 'none',
  },
};
Object.assign(styles.customButton, mixins.borderRadus('20px'));
Object.assign(styles.customButton, fontmixins.OpenSansSemibold());

export default styles;
