import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Button from '@core/components/Button';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import colors from '@styles/common/color_variables.scss';
import doveText from '@assets/images/landing/dove_text.svg';
import doveLogo from '@assets/images/landing/dove_logo.svg';
import BtnPrimary from '@core/components/Typography/BtnPrimary';
import Loading from '@core/components/Loading';

import { styles } from './landing_page_style';

/**
 * @description - Landing component.
 */
class Landing extends React.PureComponent {
  /**
   * @description - Button template.
   */
  getBtnTemplate = () => {
    const { classes, btnText, btnClick } = this.props;
    return btnText ? (
      <div className={classes.btnContainer}>
        <Button
          radius="3px"
          margin="10px 0"
          textAlign="center"
          padding="10px 20px"
          onClick={btnClick}
          overrideStyles={{ display: 'flex' }}
          bgColor={colors.button_background_color_variant_1}
          boxShdw={`0 0 10px 0 ${colors.color_box_shadow_light}`}
          border={`1px solid ${colors.button_background_color_variant_1}`}
        >
          <BtnPrimary>
            <FormattedMessage id={btnText} />
          </BtnPrimary>
        </Button>
      </div>
    ) : (
      ''
    );
  };

  /**
   * @description - Message Text Template.
   */
  getMsgTemplate = () => {
    const { classes, msgText, msgStyles, mailReqd } = this.props;
    const mail = 'ICT Service Desk';
    return msgText ? (
      <div style={msgStyles} className={classes.message}>
        {msgText}
        {mailReqd ? (
          <div>
            &nbsp;
            <FormattedMessage id="DOVE_CONTACT" />
            &nbsp;
            <a
              style={{ color: colors.header_background_color, textDecoration: 'underline' }}
              href="mailto:ict.servicedesk@petronas.com.my"
            >
              {mail}
            </a>
            {'.'}
          </div>
        ) : (
          ''
        )}
      </div>
    ) : (
      ''
    );
  };

  /**
   * @description - Return loader.
   */
  getLoaderTemplate = () => {
    const { showLoader } = this.props;
    return showLoader ? <Loading /> : '';
  };

  /**
   * @description - Function to render Landing component.
   */
  render() {
    const { classes, showDoveText } = this.props;
    return (
      <div className={classes.landingPage}>
        <div className={classes.landingContainer}>
          <img className={classes.doveLogo} src={doveLogo} alt="DOVE Logo" />
          {showDoveText ? <img className={classes.doveText} src={doveText} alt="DOVE" /> : ''}
          {this.getBtnTemplate()}
          {this.getMsgTemplate()}
          <div className={classes.loader}>{this.getLoaderTemplate()}</div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  btnClick: PropTypes.func,
  btnText: PropTypes.string,
  msgText: PropTypes.string,
  showLoader: PropTypes.bool,
  showDoveText: PropTypes.bool,
  msgStyles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  classes: PropTypes.shape({}).isRequired,
  mailReqd: PropTypes.bool,
};

Landing.defaultProps = {
  btnText: '',
  msgText: '',
  /**
   * @description - Default button click handler.
   */
  btnClick() {},
  showLoader: false,
  msgStyles: {},
  showDoveText: true,
  mailReqd: false,
};

export default withRouter(injectSheet(styles)(Landing));
