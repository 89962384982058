import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './btn_primary_style';

/**
 * @description - Describes the text component.
 * @param {Object} props - Text component properties.
 * @returns {string} - HTML templete of the text component.
 */
const BtnPrimary = props => {
  const { children, classes } = props;
  return <span className={classes.fontstyle}>{children}</span>;
};

BtnPrimary.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
export default injectSheet(styles)(BtnPrimary);
