import role from '@core/base/role';

/**
 * @description - Get Role.
 * @param {*}  roleval - Role.
 */
export function getRole(roleval) {
  let roleparam = '';
  Object.entries(role).forEach(([key, value]) => {
    if (roleval === value) {
      roleparam = key;
    }
  });
  return roleparam;
}

/**
 * @description - Get  base url
 */
export function getApiDomain() {
  const { host, protocol } = window.location;
  let protocolMap = protocol;
  let apiHost = `api.${host}`;
  if (host.includes('localhost')) {
    apiHost = `api.${process.env.REACT_APP_ENV}.dove.petronas.com`;
    protocolMap = 'https:';
  }
  const protocolHash = '//';
  const apiDomain = `${protocolMap}${protocolHash}${apiHost}`;
  return apiDomain;
}

/**
 * @description - Get base url with end point.
 * @param {string} endPoint - Endpoint.
 * @returns {string} - Base url with endp point.
 */
export function getEndpoint(endPoint) {
  return getApiDomain() + endPoint;
}
