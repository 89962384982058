import { spawn } from 'redux-saga/effects';
import pollRequestWatcher from './pollWatcher';
import watchAuthToken from './getAuthToken';
import watchUserClaims from './getUserClaim';
import watchRefreshToken from './getRefreshToken';
import watchClearToken from './clearAuthToken';
import downloadFileWatcher from './fileDownload';

/**
 * @description - Rootsaga.
 */
export default function* rootSaga() {
  yield 'RootSaga';
  yield spawn(watchAuthToken);
  yield spawn(watchUserClaims);
  yield spawn(pollRequestWatcher);
  yield spawn(watchRefreshToken);
  yield spawn(watchClearToken);
  yield spawn(downloadFileWatcher);
}
