import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchAPI } from '@core/sagas/fetchAPI';
import { httpMethod } from '@core/enums/httpMethod';
import fileSaver from 'file-saver';
import { failureToast } from '@core/actions';
import { DOWNLOAD_FILES } from '../actions/actionTypes';

/**
 * @description - Check match method.
 * @param {Array} matches - Matches.
 * @returns {boolean} - Returns match or not.
 */
const checkMatch = matches => matches != null && matches[1];

/**
 * @description - Check attachment.
 * @param {string} disposition - Disposition.
 * @returns {boolean} - Returns hasAttachment or not.
 */
const hasAttachment = disposition => disposition && disposition.indexOf('attachment') !== -1;

/**
 * @description - Takes endpoint,calls API function & update the store.
 * @param {Object} response - Response.
 */
function getFilename(response) {
  let filename = '';
  const disposition = response.headers['content-disposition']; // response.headers.get('Content-Disposition');
  if (hasAttachment(disposition)) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (checkMatch(matches)) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}

/**
 * @description - Function to show failed toaster.
 * @param {number} count - Failed file count.
 * @returns {Object} - Toast result.
 */
function* showFailedToast(count) {
  let res = {};
  if (count > 0) {
    window.scrollTo(0, 0);
    const str = count === 1 ? 'document' : 'documents';
    res = yield put(failureToast(`Failed to download ${count} ${str}`));
  }
  return res;
}

/**
 * @description - Function to download a list of files.
 * @param {Array} fileList - File list.
 */
function downloadFileList(fileList) {
  fileList.forEach(element => {
    if (element.data) {
      const filename = getFilename(element);
      fileSaver.saveAs(element.data, filename);
    }
  });
}

/**
 * @description - Download files.
 * @param {Object} param - Param.
 * @returns {undefined}
 */
function* downloadFiles(param) {
  yield put({ type: param.payload.requestType, message: '' });
  const options = {};
  const res = [];
  let failedCount = 0;
  for (let i = 0; i < param.payload.files.length; i++) {
    try {
      res[i] = yield call(
        fetchAPI,
        param.payload.endPoint[i],
        param.payload.files[i],
        httpMethod.DOWNLOAD,
        options,
      );
    } catch (err) {
      res[i] = {};
      failedCount += 1;
      yield put(param.payload.errorAction(''));
    }
  }
  yield call(showFailedToast, failedCount);
  downloadFileList(res);
}

/**
 * @description - Download file generator function.
 */
export default function* downloadFileWatcher() {
  yield takeEvery(DOWNLOAD_FILES, downloadFiles);
}
