import { GET_USER_CLAIM_SUCCESS, GET_USER_CLAIM_FAILED } from '@core/actions/actionTypes';

const initialState = {
  isValidRole: 2,
  firstName: '',
  lastName: '',
};
/**
 * @description - User reducer.
 * @param {Object} state - State.
 * @param {Object} action - Action.
 * @returns {Object} - Updated state.
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CLAIM_SUCCESS:
      return action.payload;

    case GET_USER_CLAIM_FAILED:
      return Object.assign({}, state, {
        isValidRole: 0,
      });
    default:
      return state;
  }
};
export default userReducer;
