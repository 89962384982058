import { takeEvery, call, put } from 'redux-saga/effects';
import fetchAPI from '@core/sagas/fetchAPI';
import { httpMethod } from '@core/enums/httpMethod';
import { LOGOUT, TOKEN_NOT_AVAILABLE } from '@core/actions/actionTypes';
import FeatureFlag from '@core/utils/featureFlag';
import { getEndpoint } from '@core/utils/configHelper';
import config from '@core/base/config';
/**
 * @description - Clear session token.
 */
function clearSessionToken() {
  window.sessionStorage.clear();
  window.localStorage.clear();
}

/* eslint-disable complexity */
/**
 * @description - Generator function to analyse response.
 * @param {Object} response - Response.
 */
function* analyseResponse(response) {
  const STR_SUCCESS = 'SUCCESS';
  const isADFS = FeatureFlag.AZUREADFSLOGOUT;
  const STR_PRIMEDEV = 'primedev';
  if (response.statusMessage.toUpperCase() === STR_SUCCESS) {
    yield call(clearSessionToken);
    const url = 'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0&wreply=';
    const urlendpoint =
      url +
      getEndpoint(config.endpoints.auth) +
      (process.env.REACT_APP_ENV === STR_PRIMEDEV ? 'local' : '');
    if (isADFS) window.location.href = urlendpoint;
    yield put({ type: LOGOUT.SUCCESS, payload: response });
  } else {
    const errorObj = { message: 'Invalid response' };
    throw errorObj;
  }
}

/**
 * @description - Generator function to clear token.
 * @param {Object} action - Action.
 */
function* clearToken(action) {
  try {
    if (action.payload) {
      const res = yield call(fetchAPI, 'logoutToken', {}, httpMethod.GET);
      yield call(analyseResponse, res);
    } else {
      // yield call(clearSessionToken);
      yield put({ type: TOKEN_NOT_AVAILABLE, payload: {} });
    }
  } catch (error) {
    clearSessionToken();
    yield put({ type: LOGOUT.FAIL, payload: { message: error } });
  }
}

/**
 * @description - Watches for the logout token.
 */
export default function* watchClearToken() {
  yield takeEvery(LOGOUT.GET, clearToken);
}
