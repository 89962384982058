import React from 'react';
import { connect } from 'react-redux';
import RoleManager from '@core/components/Landing/roleManager';
import Landing from '@core/components/Landing/LandingPage';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getToken } from '@core/actions';
import { signIn, clearStorage, getTokenStatus } from '@core/base/auth';

/**
 * @description - Base component.
 */
class Base extends React.Component {
  /**
   * @description - ComponentDidMount lifecycle.
   */
  componentDidMount() {
    const { dispatch, location } = this.props;
    dispatch(getToken(location.search));
  }

  /**
   * @description - On login click.
   */
  onLoginClicked = () => {
    signIn();
  };

  /**
   * @description - Function to render CheckTokenStatus component.
   * @returns {Object} - Return template.
   */
  render() {
    return (
      <React.Fragment>
        {/* <Loader /> */}
        <CheckTokenStatus {...this.props} loginClicked={this.onLoginClicked} />
      </React.Fragment>
    );
  }
}

/**
 * @description - Render component based on token status.
 * @param {Object} props - Props.
 */
const CheckTokenStatus = props => {
  const { token, loginClicked, tokenStatus } = props;
  let renderedComponent;
  const status = getTokenStatus(token, tokenStatus);
  if (status === 0) {
    renderedComponent = <Landing showLoader showDoveText={false} />;
  } else if (status === 1) {
    renderedComponent = <RoleManager token={token} />;
  } else {
    clearStorage();
    renderedComponent = <Landing btnText="DOVE_GO_LOGIN" btnClick={loginClicked} />;
  }
  return renderedComponent;
};

CheckTokenStatus.propTypes = {
  token: PropTypes.shape({}).isRequired,
  loginClicked: PropTypes.func.isRequired,
};

/**
 * @description - Connect component with store.
 * @param {Object} state - Store state.
 * @returns {Object} - Updated state.
 */
function mapStateToProps(state) {
  return {
    token: state.tokenReducer,
  };
}
Base.defaultProps = {
  tokenStatus: 0,
};

Base.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  tokenStatus: PropTypes.number,
};

export default withRouter(connect(mapStateToProps)(Base));
