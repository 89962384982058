import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { checkUserSessionAndRole } from '@core/base/auth';

const fakeAuth = {
  /**
   * @description -Function to aunthenticate user's role.
   * @param {string} role - Logged in user's role.
   * @returns {boolean} - Returns if the user has a vald role.
   */
  authenticate(role) {
    return checkUserSessionAndRole(role);
  },
};

/**
 * @description -Function to route based on user's role.
 * @param {Object} component - Component to whih user has to be redirected based on user's role, Logged in user's role,is user session valid.
 * @returns {string} - Route based on user's role .
 */
const PrivateRoute = ({ component: Component, role: Role, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      fakeAuth.authenticate(Role) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              from: props.location,
              errorMessage: '',
              mailReqd: false,
            },
          }}
        />
      )
    }
  />
);
export default PrivateRoute;
