import Log from '@core/base/logger';

/**
 * @description - Load state.
 * @returns {Object} - JSON object for serialized state from session storage.
 */
export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

/**
 * @description - Save state in session storage.
 * @param {Object} state - State.
 */
export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    window.localStorage.setItem('state', serializedState);
  } catch (error) {
    Log.catchError(error, 'Error while saving state');
  }
};
