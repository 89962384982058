import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { withRouter } from 'react-router-dom'; // Link
import { FormattedMessage } from 'react-intl';
import doveLogo from '@assets/images/core/logo/dove_green.svg';

import styles from './style';

/**
 * @description - Version component .
 */
class Version extends React.PureComponent {
  loadDetails = () => {
    const version = `1.1.38`;
    const date = `Wednesday, September 11th, 2024, 3:10:23 AM`;
    const { classes } = this.props;

    return (
      <div className={classes.detailsContaier}>
        <div className={classes.versionContainer}>
          <span className={classes.label}>
            <FormattedMessage id="DOVE_VERSION" />: &nbsp;
          </span>
          {version}
        </div>
        <div className={classes.dateContainer}>
          <span className={classes.label}>
            <FormattedMessage id="DOVE_VERSION_DATE" />: &nbsp;
          </span>
          {date}
        </div>
        <div className={classes.browserContainer}>
          <span className={classes.label}>
            <FormattedMessage id="DOVE_USER_AGENT" />: &nbsp;
          </span>
          {navigator.userAgent}
        </div>
      </div>
    );
  };

  /**
   * @description - Render component
   * @returns {string} - HTML for Version.
   */
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <div className={classes.logoContaier}>
          <img src={doveLogo} alt="dove" />
        </div>
        {this.loadDetails()}
        <span className={classes.copyRight}>
          <FormattedMessage id="DOVE_footer" />
          {new Date().getFullYear()}
          <FormattedMessage id="DOVE_footertitle" />
        </span>
      </div>
    );
  }
}
Version.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withRouter(injectSheet(styles)(Version));
