import mixins from '@styles/common/mixins';
import fontmixins from '@styles/fontmixins';
import colors from '@styles/common/color_variables.scss';

const styles = {
  fontstyle: {
    ...fontmixins.OpenSansSemibold(),
    ...mixins.pxtorem(13),
    color: colors.button_text_white_color,
  },
};

export { styles as default };
