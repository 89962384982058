/* eslint-disable max-lines */
/* eslint-disable */
const ENGLISH_TRANSLATION = {
  locale: 'en',
  messages: {
    DOVE_M_VOL: 'Monthly Vol.',
    DOVE_YTD_VOL: 'YTD Vol.',
    DOVE_Baseline_60: '( Baseline is 60 hrs )',
    DOVE_Baseline_73: '( Baseline is 73 hrs )',
    DOVE_Baseline_54: '( Baseline is 54 hrs )',
    DOVE_SwitchDetails: 'Switch to Malay',
    DOVE_StationInv: 'Station Inventory',
    DOVE_footer: '© ',
    DOVE_footertitle: ' PETRONAS DOVE. All Rights Reserved',
    DOVE_MmsStatus: 'MMS Status',
    DOVE_MMSstatus: 'MMS status',
    DOVE_Cargo: 'Cargo',
    DOVE_Speed: 'Speed',
    DOVE_BunkerLevel: 'Bunker consumption',
    DOVE_Laycan: 'Laycan',
    DOVE_Agent: 'Agent',
    DOVE_VoyagePlan: 'Voyage plan',
    DOVE_FreightCost: 'Freight cost',
    DOVE_PoCost: 'PO cost',
    DOVE_Departed: 'Departed',
    DOVE_Eta: 'ETA',
    DOVE_CTSOVERALL: 'CTS - OVERALL',
    DOVE_OverallInvenotry: 'OVERALL INVENTORY',
    DOVE_Volume: 'Volume',
    DOVE_InvCost: 'Inv cost',
    DOVE_KB: 'KB',
    DOVE_RM: ' RM',
    DOVE_RMPERL: '(RM/L)',
    DOVE_Days: 'Days',
    DOVE_StockDays: 'Stock days',
    DOVE_demandPlanner_Stock_Days: 'Stock days',
    DOVE_CTS: 'ENTERPRISE COST TO SERVE',
    DOVE_PDB_CTS: 'PDB RETAIL COST TO SERVE',
    DOVE_OCC: 'OIL COMPANIES SELLING PRICE COMPARISON',
    DOVE_CUSTOMER: 'Sabah Electricity Sdn. Bhd.',
    DOVE_PDBVSSHEL: 'PDB vs SHELL',
    DOVE_PDBVSPETRON: 'PDB vs PETRON',
    DOVE_SHELVSSHEL: 'SHELL vs PETRON',
    DOVE_OCC_TREND: 'OIL COMPANIES SELLING PRICE TREND',
    DOVE_OperationalHighlights: 'OPERATIONS HIGHLIGHTS',
    DOVE_OperationalAlerts: 'OPERATIONAL ALERTS',
    DOVE_CtsShort: 'CTS',
    DOVE_PDBCtsShort: 'PDB CTS',
    DOVE_NoDataMsg: 'No Data',
    DOVE_DepotPerformance: 'DEPOT PERFORMANCE',
    DOVE_For: 'For',
    DOVE_AsOn: 'As on',
    DOVE_AsOf: 'As of ',
    DOVE_asOf: 'as of ',
    DOVO_LodingMsg: 'Loading...',
    DOVO_Mops: 'MOPS',
    DOVE_OverallSalesVariance: 'OVERALL SALES VARIANCE (MTD)',
    DOVE_EnterpriseCTS: 'CTS Enterprise',
    DOVE_Back: 'Back',
    DOVE_MLS: 'MONTHLY LIFTING SCHEDULE',
    DOVE_CTS_AsOn: 'As on ',
    DOVE_ErrorComponent: 'Error component will come here',
    DOVE_EmptyComponent: 'Nothing due today. Be a go better, and check back soon',
    DOVE_EmptyGraph_1: 'Feel like no data for ',
    DOVE_EmptyGraph_2: ' today. Be a go better, and check back soon',
    DOVE_CtsOverallGraph: 'CTS - ACTUAL VS FORECAST',
    DOVE_DoNo: 'PETCO Voyage No.',
    DOVE_LDR: 'LDR',
    DOVE_LoadPort: 'Load Port',
    DOVE_DischargePort: 'Discharge Port',
    DOVE_Vessel: 'Vessel',
    DOVE_Products: 'Products',
    DOVE_EditDetails: 'Edit details',
    DOVE_SaveChanges: 'Save Changes',
    DOVE_Save_Remarks: 'Save remarks',
    DOVE_COPY_REASONS: 'Copy the reason(s) to other depots',
    DOVE_MDF: 'MONTHLY DEMAND FORECAST',
    DOVE_Button_Publish: 'Publish',
    DOVE_Button_Download: 'Download',
    DOVE_Button_RunTheMLS: 'Run the MLS',
    DOVE_Edit: 'Edit',
    DOVE_Upload: 'Upload',
    DOVE_Download: 'Download',
    DOVE_Send_Vi: 'Send VI',
    DOVE_Send_DI: 'Send DI',
    DOVE_Cancel: 'Cancel',
    DOVE_Update: 'Update',
    DOVE_Lifting_Tracker: 'REFINERY LIFTING TRACKER',
    DOVE_DI_Special_Instruction1:
      'SURVEYOR PLEASE OBTAIN PRODUCT SAMPLE FROM SHORE MANIFOLD PRIOR TO LOADING OPERATION AND KINDLY RETAIN THE SAMPLE FOR MIN OF 3 MONTHS AND ONE SAMPLE ONBOARD VESSEL.',
    DOVE_DI_Special_Instruction2:
      'PLS TO CFRM YR APPMT AS QNTY SVYR FOR ABV LFTG AS PER OUR STD TERM OF REF ST/SVYR. KINDLY CFRM THE NAME(S) OR YR PERSONNEL ATTNDG ABV SHIPMENT.',
    DOVE_VI_ETA_CLAUSE:
      'IN ADDITION TO THIS NOTICE, MASTER IS TO FOLLOW PETRONAS ETA CLAUSE AS PER CHARTER PARTY.',
    DOVE_VI_EXTENDED_QUANTITY:
      'However please Liaise with Terminal for exact quantity to be loaded.',
    DOVE_VI_NOTICE_ADDITION:
      'IN ADDITION TO THIS NOTICE, MASTER IS TO FOLLOW PETRONAS ETA CLAUSE AS PER CHARTER PARTY.',
    DOVE_VI_NOTICE_10: '10.',
    DOVE_VI_NOTICE_10_CONTENT:
      'VSL MASTER TO CARRY TWO (2) ORIGINAL B/L FOR RECEIVING TERMINALS ENDORSEMENT AND ONE COPY ORIGINAL B/L TO BE FORWARDED TO TERMINAL BY SHIP AGENTS. EARLY DEPARTURE PROCEDURE (EDP).',
    DOVE_VI_NOTICE_11: '11.',
    DOVE_VI_NOTICE_11_CONTENT:
      'VSL MASTER MUST COORDINATE VERY CLOSELY WITH TERMINAL / AGENTS SO AS TO ENSURE SMOOTH FLOW OF OPERATION.',
    DOVE_VI_NOTICE_12: '12.',
    DOVE_VI_NOTICE_12_CONTENT:
      "MASTER TO ENSURE ALL VESSEL'S TANKS, PIPES AND PUMPS ARE PROPERLY CLEANED, DRY AND FIT TO LOAD THE DESIGNATED CARGO TO AVOID ANY POSSIBLE CARGO CONTAMINATION.",
    DOVE_VI_NOTICE_13: '13.',
    DOVE_VI_NOTICE_13_CONTENT:
      'AFTER COMPLETION OF LOADING AND DISCHARGE, OWNER OR ITS AGENT TO IMMEDIATELY TELEX CHARTERER PROVIDING THE FOLLOWING DETAILS:-',
    DOVE_VI_NOTICE_16: '16.',
    DOVE_VI_NOTICE_16_CONTENT:
      'MASTER, OR HIS DELEGATE, SHALL ENSURE THE VESSEL IS UPRIGHT AND EVEN-KEELED DURING CARGO TANKS INSPECTION AFTER COMPLETION OF LOADING AND BEFORE COMMENCEMENT OF DISCHARGE. WHERE THE VESSEL UNABLE TO MEET THIS REQUIREMENT DUE TO STABILITY LIMITATION OR ANY OPERATIONAL CONSTRAINTS, THE MASTER SHALL NOTIFY PDB TEAM WITHIN 24 HOURS OF RECEIVING THIS INSTRUCTION',
    DOVE_VI_NOTICE_17: '17.',
    DOVE_VI_NOTICE_17_CONTENT: 'OWNER/VSL MASTER TO PROVIDE STOWAGE PLAN FOR NOMINATED CARGO.',
    DOVE_VI_NOTICE_18: '18.',
    DOVE_VI_NOTICE_18_CONTENT: 'PLS COMPLY TO ABOVE VOY. INSTRUCTIONS ACCORDINGLY.',
    DOVE_Leading_By: 'Leading by',
    DOVE_Lagging_By: 'Lagging by',
    DOVE_LIFTED: 'lifted',
    DOVE_OVERLIFTED: 'overlifted',
    DOVE_UNDERLIFTED: 'underlifted',

    DOVE_From_Plan: 'from plan',
    DOVE_Etc: 'ETC',
    DOVE_vessels_overview: 'VESSELS OVERVIEW',
    DOVE_Drange: 'LOADING D/RANGE',
    DOVE_Our_ship_ref: 'OUR SHIPMENT REFERENCE',
    DOVE_Abv_particulars: 'ABV PARTICULARS',
    DOVE_Makeup: 'AA) B/L MAKEUP',
    DOVE_Consignor: 'CONSIGNOR',
    DOVE_Consignee: 'CONSIGNEE',
    DOVE_Destination: 'DESTINATION',
    DOVE_Bdocs: 'BB) DOCS DISTRIBUTION',
    DOVE_Cif_At: 'CC) CIF AT 15C (RM/LTR)',
    DOVE_DEPOT: 'DEPOT',
    DOVE_Product: 'PRODUCT',
    DOVE_C: ' C',
    DOVE_I: 'I',
    DOVE_F: 'F',
    DOVE_Cif: 'CIF',
    DOVE_Do_Particulars: 'DD) D/O PARTICULARS ARE AS FOLLOWS:',
    DOVE_Dest: 'DEST',
    DOVE_Ddr: 'DDR',
    DOVE_Quality_Litres:
      'EE) PLS INCLUDE QUANTITY LITRES AT OBSERVED TEMP AND KL AT 30 DEG C IN THE B/L (FOR CLEAN & DIRTY PRODUCTS ONLY)',
    DOVE_Joint_Quality: 'FF) JOINT QUANTITY SURVEYOR:',
    DOVE_Loadport: 'LOADPORT',
    DOVE_Surveyor: 'SURVEYOR',
    DOVE_Cost: 'COST',
    DOVE_Disport: 'DISPORT',
    DOVE_Special_Instruction1: 'GG) SPECIAL INSTRUCTION #1 ',
    DOVE_Special_Instruction2: 'SPECIAL INSTRUCTION #2 : BCC TO SURVEYOR',
    DOVE_Pls_Ensure_Below: 'PLEASE ENSURE FULL COMPLIANCE TO BELOW INSTRUCTIONS',
    DOVE_Hh_Ship: 'HH) SHIPPING AGENT',
    DOVE_Load_Agent: 'LOADPORT AGENT',
    DOVE_Ship_Agent: 'DISPORT AGENT',
    DOVE_11_Disport: 'II) DISPORT FORWARDING AGENT',
    DOVE_Parcel_no: 'PARCEL NO',
    DOVE_Loading_Terminal: 'Loading terminal',
    DOVE_Mode_of_Transfer: 'Mode of transfer',
    DOVE_Parcel: 'Parcel',
    DOVE_PARCEL: 'PARCEL',
    DOVE_Loaded_Vol: 'Loaded volume',
    DOVE_Load_Port: 'Load port',
    DOVE_PARCEL_NOMINATED: 'Parcel (Nominated)',
    DOVE_PARCEL_NOMINATED2: 'Product',
    DOVE_PARCEL_NOMINATED22: 'Saved as Draft',
    DOVE_PARCEL_NOMINATED222: 'Approved',
    DOVE_PARCEL_NOMINATED2222: 'Pending',
    DOVE_PARCEL_NOMINATED22222: 'Rejected',
    DOVE_MLS_TABLE_HEADER_DOCUMENT: 'PETCO Voyage No.',
    DOVE_MLS_TABLE_HEADER_LDR: 'LDR',
    DOVE_ETA_MELAKA: 'ETA Melaka',
    DOVE_MLS_TABLE_HEADER_LOAD_PORT: 'Load port',
    DOVE_MLS_TABLE_HEADER_ATA: 'ATA',
    DOVE_MLS_TABLE_HEADER_DISCHARGE_PORT: 'Discharge port',
    DOVE_MLS_TABLE_HEADER_VESSEL: 'Vessel',
    DOVE_MLS_TABLE_HEADER_PARCEL: 'Parcel',
    DOVE_MLS_TABLE_HEADER_LOADED_VOL: 'Loaded vol',
    DOVE_MLS_TABLE_HEADER_BERTH: 'Berth',
    DOVE_MLS_TABLE_HEADER_BILL_DATE: 'BL/Date',
    DOVE_MLS_TABLE_HEADER_REMARKS: 'Remarks',
    DOVE_MLS_DELETE_OK: 'Yes, delete',
    DOVE_MLS_SEND: 'Send',
    DOVE_RETAIL_SUBMIT_OK: 'Yes, Submit',
    DOVE_EOA_SUBMIT_OK: 'Yes, Approve',
    DOVE_EOA_REJECT_OK: 'Yes, Reject',
    DOVE_RETAIL_SAVE_OK: 'Save',
    DOVE_VI_SHIPMENT_REF: 'SHIPMENT REF NO.',
    DOVE_VI_VOYAGE_INSTRUCTION: 'VOYAGE INSTRUCTIONS ARE AS FOLLOW:',
    DOVE_VI_CHARTERER: '1. CHARTERER',
    DOVE_VI_LOAD_PORT: '2. LOADPORT',
    DOVE_VI_LAY_CAN: '3. LAYCAN',
    DOVE_VI_CARGO_TYPE: '4. CARGO TYPE',
    DOVE_VI_DISPORT: '5. DISPORT',
    DOVE_VI_SLOPS: '6. SLOPS',
    DOVE_VI_BILL_MAKE_UP: '7. B/L MAKE-UP CONSIGNOR /CONSIGNEE DEST',
    DOVE_VI_DOCS_REQ_LOAD_PORT: '8. DOCUMENTS REQUIRED AT LOAD/DISPORT',
    DOVE_VI_ETA_LABEL: '9. ETA NOTICE',
    DOVE_VI_LOAD_PORT_TERMINAL_LABEL: '1. LOADPORT TERMINAL',
    DOVE_CONTACT_PERSON: 'CONTACT PERSON',
    DOVE_EMAIL: 'EMAIL',
    DOVE_VI_LOAD_PORT_SURVEYOR: 'LOAD PORT SURVEYOR',
    DOVE_VI_LOAD_PORT_AGENT: 'LOAD PORT AGENT',
    DOVE_BERTH_LOAD_PORT_AGENT: 'Melaka port agent',
    DOVE_BERTH_LOAD_PORT_SURVEYOR: 'Melaka port surveyor',
    DOVE_VI_DISCHARGE_PORT_SURVEYOR: 'DISCHARGE PORT SURVEYOR',
    DOVE_VI_DISCHARGE_PORT_AGENT: 'DISCHARGE PORT AGENT',
    DOVE_VI_DISPORT_TERMINAL: '2. DISPORT TERMINAL',
    DOVE_VI_CHARTERER_HEADING: '3. CHARTERER',
    DOVE_VI_SURVEYOR_HEADING: '14. SURVEYOR & AGENT',
    DOVE_VI_SPECIAL_INSTRUCTION_HEADING: '15. SPECIAL INSTRUCTION',
    DOVE_VI_PTLCL: 'PTLCL',
    DOVE_VI_PDB: 'PDB',
    DOVE_GL_CODE: 'GL Code',
    DOVE_DESCRIPTION: 'Description',
    DOVE_COST: 'Cost',
    DOVE_PLANNED_BUDGET: 'Planned Budget',
    DOVE_View_Complete_Schedule: 'View Complete Schedule',
    DOVE_View_MLS_Schedule: 'View monthly lifting schedule',
    DOVE_ForAction: 'FOR YOUR ACTION',
    DOVE_InvAlerts: 'INVENTORY ALERTS',
    DOVE_Alerts: 'ALERTS',
    DOVE_MoreDetails: 'More details',
    DOVE_ViewDetails: 'View Details',
    DOVE_Vessel_Onboard: 'On board the vessel',
    DOVE_Reciever: 'For receiver C/O Master',
    DOVE_Master: 'For Master',
    DOVE_Enter: 'Enter here',
    DOVE_Justification: 'Enter justification here',
    DOVE_DI_TABLE_CONDITIONS: '*Issued or countersigned by loadport inspectors',
    DOVE_JUSTIFICATION: 'JUSTIFICATION',
    DOVE_MLS_ADD_NEW: 'Add New',
    DOVE_Save: 'Save',
    DOVE_Gross_Margin_Ytd: 'Gross margin (YTD)',
    DOVE_Gross_Margin_Mtd: 'Gross margin (MTD)',
    DOVE_Vol_Ytd: 'Sales volume (YTD)',
    DOVE_Vol_Mtd: 'Sales volume (MTD)',
    DOVE_Pump_Price: 'Pump price',
    DOVE_ML: 'ML',
    DOVE_L: 'L',
    DOVE_M: 'M',
    DOVE_B: 'B',
    DOVE_BL: 'BL',
    DOVE_GL_Codes: 'GL Codes',
    DOVE_Daily: 'Daily',
    DOVE_PRICING_INVENTORY: 'INVENTORY',
    DOVE_STATIONS_TITLE: 'STATION',
    DOVE_STATIONS: 'Stations',
    DOVE_Retail_Stock_Left: 'Retail stock left',
    DOVE_Retail_Allocated_Stock: 'Retail allocated stock (KL)',
    DOVE_Allocated_Stock: 'Allocated stock',
    DOVE_KL: '(KL)',
    DOVE_Retail_Stock: 'Retail stock',
    DOVE_Retail_Percnt: '(%)',
    DOVE_Total_Inventory: 'Total inventory',
    DOVE_Retail_RM: '(RM)',
    DOVE_RML: '(RM/L)',
    DOVE_Forecast_CTS: 'Forecast CTS',
    DOVE_CTS_Title: 'CTS',
    DOVE_MAP: 'MAP',
    DOVE_Forecast_MAP: 'Forecast MAP',
    DOVE_Margin_L: 'Margin/L',
    DOVE_Stock_Left: 'Stock Left',
    DOVE_Opening_Stock: 'Opening Stock',
    DOVE_Retail_ML: '(ML)',
    DOVE_LITRE: '/Litre',
    DOVE_Stock: 'Stock',
    DOVE_Stock_Days: 'Total stock days',
    DOVE_CTS_Margin: 'CTS margin',
    DOVE_MAP_Margin: 'MAP margin',
    DOVE_Percent_Margin: '% margin',
    DOVE_Gross_Margin: 'Gross Margin',
    DOVE_TANK_CAPACITY: 'Tank Capacity',
    DOVE_Last_Updated: 'Last updated on ',
    DOVE_DATA_EMAIL: 'This data was recieved via email',
    DOVE_Vol_Updated: 'Volume last updated on',
    DOVE_Estimated_Freight_Cost: 'Estimated freight cost',
    DOVE_Unit_Cost: 'Unit cost',
    DOVE_CurrentInventory: 'CURRENT INVENTORY',
    DOVE_Depo: 'Depot',
    DOVE_Inventory: 'Inventory',
    DOVE_Current_Map: 'Current MAP',
    DOVE_Total: 'Total',
    DOVE_Average: 'Average',
    DOVE_IncomingReplenishments: 'INCOMING REPLENISHMENTS',
    DOVE_Shipment: 'Shipment #',
    DOVE_Po: 'PO #',
    DOVE_PoValue: 'PO Value',
    DOVE_NewMap: 'New Map',
    DOVE_PrevMap: 'Previous Map',
    DOVE_EstGrDate: 'Est. GR Date',
    DOVE_ICOM_PRCING_INVENTORY: 'Inventory Competitiveness Optimization Model (ICOM)',
    DOVE_Login_With_Petronas_ID: 'LOGIN WITH PETRONAS ID',
    DOVE_GO_LOGIN: 'Login with PETRONAS',
    DOVE_MPP_TABLE_HEADER_DATE: 'Date',
    DOVE_MPP_TABLE_HEADER_PARCEL_NUMBER: 'Parcel no.',
    DOVE_MPP_TABLE_HEADER_LOADING_TERMINAL: 'Loading terminal',
    DOVE_MPP_TABLE_HEADER_DISCHARGE_PORT: 'Discharge port',
    DOVE_MPP_TABLE_HEADER_DISCHARGE_TERMINAL: 'Discharge port',
    DOVE_MPP_TABLE_HEADER_Product: 'Product',
    DOVE_MPP_TABLE_HEADER_QTY: 'Qty',
    DOVE_MPP_TABLE_HEADER_ACTUAL_VOL: 'Act Vol',
    DOVE_MPP_TABLE_HEADER_ETA: 'ETA',
    DOVE_MPP_TABLE_HEADER_BILL_DATE: 'B/L date',
    DOVE_MPP_TABLE_HEADER_REMARKS: 'Remarks',
    DOVE_LTR: '/L',
    DOVE_days: 'days',
    DOVE_days_unit: '(days)',
    DOVE_OVERALLDEMAND: 'OVERALL DEMAND',
    DOVE_Up_Dep: 'UPCOMING AND DEPARTED VESSELS',
    DOVE_Status: 'Status',
    DOVE_Charterer: 'Charterer',
    DOVE_DO_Parcel: 'D/O No parcel ',
    DOVE_Actions: 'Actions',
    DOVE_Upcoming: 'List of upcoming vessels at Melaka between ',
    DOVE_Departed_Vessel: 'List of departed vessels at Melaka ',
    DOVE_Upcoming_Discharge: 'List of upcoming vessels at Melaka for discharging',
    DOVE_Upcoming_Loading: 'List of upcoming vessels at Melaka for loading',
    DOVE_Stowage_Plan: 'Stowage Plan',
    DOVE_Time_Log: 'Time Log',
    DOVE_Bill_Lading: 'Bill of Lading',
    DOVE_DownloadAll: 'Download All',
    DOVE_EMPTY: ' ',
    DOVE_REMARKS: 'Remarks',
    DOVE_BERTH_OCCUPANCY_COMMENCED_PUMPING: 'Commenced pumping at',
    DOVE_BERTH_OCCUPANCY_REMARKS: 'Remarks',
    DOVE_MAPvsMOPS: 'MAP vs MOPS',
    DOVE_ACTIVE_REBATES: 'ACTIVE REBATES',
    DOVE_HI: 'Hi',
    DOVE_VESSEL_TRACKER: 'VESSEL TRACKER',
    DOVE_BERTH_OCCUPANCY: `MRCSB BERTH OCCUPANCY`,
    DOVE_SENT_ON: 'Sent on ',
    DOVE_DI_TO_BE_SENT: 'DI to be sent',
    DOVE_REF: 'REF: ',
    DOVE_DOCUMENTARY_INSTRUCTIONS: 'DOCUMENTARY INSTRUCTIONS ',
    DOVE_RE: 'RE: ',
    DOVE_OPERATIONAL_EXPENSES: 'OPERATIONAL EXPENSES ',
    DOVE_TILL_DATE: 'till date',
    DOVE_Month_To_Date: 'Month To Date',
    DOVE_MN: 'mn',
    DOVE_MIL: 'Mil',
    DOVE_TC: 'Term charter',
    DOVE_YEARLY_ALLOCATED_BUDGET: 'Yearly allocated budget',
    DOVE_MONTHLY_ALLOCATED_BUDGET: 'Monthly allocated budget',
    DOVE_SPOT_HIRE: 'Spot hire',
    DOVE_SPOT_HIRE_MONTH: ' Spot hire this month',
    DOVE_Overall: 'Overall',
    DOVE_VOYAGE_INSTRUCTIONS: 'VOYAGE INSTRUCTIONS',
    DOVE_VesselCaps: 'VESSEL:',
    DOVE_PORT_STATUS: 'Port Status',
    DOVE_Cents: 'Cents',
    DOVE_Rebate: 'rebate',
    DOVE_On_Purchase_of: 'on purchase of',
    DOVE_HELLO: 'Hello',
    DOVE_UPCOMING: 'UPCOMING',
    DOVE_Increase_last_year: 'increase last year',
    DOVE_TID: 'TID',
    DOVE_CB01: 'CB 01',
    DOVE_ORKIM_HARMONY: 'ORKIM HARMONY',
    DOVE_CB02: 'CB 02',
    DOVE_CB03: 'CB 03',
    DOVE_CB04: 'CB 04',
    DOVE_OB01: 'OB 01',
    DOVE_0B02: '0B 02',
    DOVE_LPG: 'LPG',
    DOVE_NAUTICA_JOHOR_BAHRU: 'NAUTICA JOHOR BAHRU',
    DOVE_CommencedPumpingAt: 'Commenced pumping at',
    DOVE_Q88: 'Q88',
    DOVE_STOWAGE_PLAN: 'Stowage Plan',
    DOVE_DO_No_PDB: 'D/O No. PDB',
    DOVE_DO_No_PDB2: 'Proposal ID',
    DOVE_PPM_1808_03: 'PPM 1808-03',
    DOVE_John_Doe: 'John Doe',
    DOVE_POB_delayed_due_to_tug_boat_issue: 'POB delayed due to tug boat issue.',
    DOVE_Arrived_at_Port: 'Arrived at Port',
    DOVE_NOR_Tendered: 'NOR Tendered',
    DOVE_Pilot_Onboard: 'Pilot Onboard',
    DOVE_All_Fast: 'All Fast',
    DOVE_Pumping_Commence: 'Pumping Commence',
    DOVE_Pumping_Completed: 'Pumping Completed',
    DOVE_Pilot_Away: 'Pilot Away',
    DOVE_Departure: 'Departure',
    DOVE_ETA_at_SBAY: 'ETA at S’BAY',
    DOVE_Tiffery_Neo: 'Tiffery Neo',
    DOVE_Report_volume_pricing_to_government: 'Report volume & pricing to government',
    DOVE_VI_TO_BE_SENT: 'VI to be sent',
    DOVE_Some_actionable_task_for_the_demand_planner: 'Some actionable task for the demand planner',
    DOVE_Map_vs_Mop: 'Map vs Mop',
    DOVE_Print: 'Print',
    DOVE_DI_Received: 'DI Received',
    DOVE_DI_Not_Received: 'DI Not Received',
    DOVE_Departed_At: 'Departed at ',
    DOVE_CBD_DEALERPRICE: 'CBD DEALER PRICE',
    DOVE_CBD_DEALERPRICE2: 'DYNAMIC TRANSFER PRICE REQUESTS',
    DOVE_CBD_ANALYTICS: 'ANALYTICS PROPOSED PRICE',
    DOVE_UPDATE_REASON: 'Enter the reason for price update',
    DOVE_OCC_COMPETITORPRICE: 'OIL COMPANIES PRICE MATRIX',
    DOVE_CBD_TIME_AT: 'at',
    DOVE_CBD_DISCARD: 'Discard',
    DOVE_CBD_SAVE_AS_DRAFT: 'Save as Draft',
    DOVE_CBD_SUBMIT_PRICE: 'Send for Approval',
    DOVE_CBD_SHARE_PRICE: 'Share',
    DOVE_CBD_UPLOAD_FILE: 'Upload',
    DOVE_CBD_SUBMIT_REVIEW: 'Submit for Review',
    DOVE_CBD_SUBMIT_APPROVAL: 'Submit for Approval',
    DOVE_CBD_SUBMIT_PRICE2: 'Create New Request',
    DOVE_CNDN_DOWNLOAD: 'Download CN/DN',
    DOVE_LOADING_COMMERCIAL: 'Loading Commercial Dashboard',
    DOVE_NO_RECORD_FOUND: 'No Record Found',
    DOVE_TC_VESSEL_UTILIZATION_MTD: 'TC VESSEL UTILIZATION',
    DOVE_DEMURRAGE_MTD: 'DEMURRAGE (MTD)',
    DOVE_OVERALL_OUTTURN_LOSS: 'OVERALL OUTTURN LOSS',
    DOVE_OVERALL_TRANSIT_LOSS: 'OVERALL TRANSIT LOSS',
    DOVE_PERFOMANCE_CLAIMS_MTD: 'PERFOMANCE CLAIMS MTD',
    DOVE_OVERALLTRANSIST_LOSS_MTD: 'OVERALL TRANSIST LOSS MTD',
    DOVE_TC_VESSEL_PERFORMANCE_OVERVIEW: 'TC VESSEL PERFORMANCE OVERVIEW',
    DOVE_Time_utilization: 'Time utilization',
    DOVE_Cap_utilization: 'Cap utilization',
    DOVE_Avg_speed: 'Avg speed',
    DOVE_Bunker_consumption: 'Bunker consumption',
    DOVE_Bunker_usage: 'Bunker usage',
    DOVE_Pumping_rate: 'Pumping rate',
    DOVE_View_details: 'View Details',
    DOVE_Contract_Details: 'Share Contract Status',
    DOVE_Contract_View_Details: 'View Contract Status',
    DOVE_Continue_Editing: 'Continue Editing',
    DOVE_COMPLETED_SHIPMENTS_BY: 'COMPLETED SHIPMENTS BY',
    DOVE_PPM_ID: 'PPM ID',
    DOVE_SHIPMENT_ID: 'Shipment ID',
    DOVE_DI_Issuance: 'DI Issuance',
    DOVE_DI_Issuance_Date: 'DI Issuance Date',
    DOVE_VoyageSummary: 'VOYAGE SUMMARY',
    DOVE_Clear: 'Clear',
    DOVE_Clear_all: 'Clear all',
    DOVE_SELECT_DATE_RANGE: 'Select Date Range',
    DOVE_Select_date_range: 'Select date range',
    DOVE_From: 'From',
    DOVE_To: 'to',
    DOVE_Apply: 'Apply',
    DOVE_STATION_PROFILE: 'STATION PROFILE',
    DOVE_KVDT: 'KVDT',
    DOVE_Approved: 'Approved ',
    DOVE_on: 'on',
    DOVE_at: 'at',
    DOVE_perL: '/L',
    DOVE_Proposed_margin_of_total_cost: 'Proposed margin of total cost',
    DOVE_Proposed_Margin_of_CTS: 'Proposed margin of CTS',
    DOVE_Set_new_commercial_price: 'Set New Commercial Price ',
    DOVE_Set_new_commercial_dealer_price_uppercase: 'SET NEW COMMERCIAL DEALER PRICE',
    DOVE_Effective_Date: 'Effective Date',
    DOVE_ADVANCED_STOCK: 'Advanced stock days calculation',
    DOVE_Standard_round_trip: 'Standard round trip',
    DOVE_AMBITION: 'AMBITION',
    DOVE_APPROVED: 'Approved',
    DOVE_VIEW_STOWAGE: 'View stowage plan',
    DOVE_TIME_UTIL: 'Time utilization',
    DOVE_CAP_UTIL: 'Cap utilization',
    DOVE_AVG_SPEED: 'Avg speed',
    DOVE_KNOTS: 'Knots',
    DOVE_OUTTURN_LOSS: 'Outturn Loss',
    DOVE_MTD_BUNKER_CONSUMPTION: 'MTD Bunker consumption',
    DOVE_MT: 'MT',
    DOVE_MT_DAY: 'Mt/day',
    DOVE_MT_PER_DAY: 'MT/day',
    DOVE_MFO: 'MFO',
    DOVE_MGO: 'MGO',
    DOVE_DAILY_BUNKER_CONSUMPTION: 'Daily Bunker Consumption',
    DOVE_PUMPING_RATE: 'Pumping rate',
    DOVE_M3_HR: 'm3/hr',
    DOVE_BAR: 'Bar',
    DOVE_DP: 'DP',
    DOVE_LP: 'LP',
    DOVE_TRANSIT_LOSS: 'Transit loss',
    DOVE_STOCK_OUT: 'STOCK OUT',
    DOVE_COMPETEITOR_PRICE_FROM_SALES_MSG: 'Competeitor price received from sales',
    DOVE_REFINERY_STOCK_MSG: 'PRAI refinery have diesel stock for 15 days only',
    DOVE_Reviewer: 'Reviewer',
    DOVE_Approver: 'Approver',
    DOVE_Cancel_Claim: 'Cancel Claim',
    DOVE_Claim_Value: 'RM 5000',
    DOVE_Estimated_Claim: 'Estimated Claim',
    DOVE_Estimated_demurrage: 'Estimated demurrage',
    DOVE_Claim_Process: 'This will cancel the claim process',
    DOVE_Claim_Cancel_Reason: 'Reason ',
    DOVE_Claim_Upload: 'Upload',
    DOVE_TC_VESSEL_CLAIMS: 'TC VESSEL CLAIMS',
    DOVE_VESSEL_CLAIM_SUMMARY: 'VESSEL CLAIM SUMMARY',
    DOVE_COMPLETED_SHIPMENTS: 'COMPLETED SHIPMENTS',
    DOVE_Claim_Type: 'Claim Type',
    DOVE_TC_Vessel: 'TC Vessel',
    DOVE_PPM__ID: 'PPM-ID',
    DOVE_Please_enter_your_comments_to_continue: 'Please enter your comments to continue.',
    DOVE_MAP_RML: 'MAP (RM/L)',
    DOVE_POME_MAP_RML: 'POME MAP (RM/L)',
    DOVE_FORECAST_POME_MAP_RML: 'Forecasted POME MAP (RM/L)',
    DOVE_POME_PO: 'POME PO Value (RM/L)',
    DOVE_REPLACEMENT_COST: 'Replacement Cost (RM/L)',
    DOVE_CTS_RML: 'CTS (RM/L)',
    DOVE_TOTAL_COST: 'Total Cost (RM/L)',
    DOVE_DEALER_PRICE: 'Dealer Price (RM/L)',
    DOVE_LOWEST_CUSTOMER_PRICE: 'Lowest Customer Price (RM/L)',
    DOVE_COMP_LOW_PRICE: 'Competitor Lowest Price (RM/L)',
    DOVE_MOPS_vs_MAP: 'MOPS vs MAP (RM/L)',
    DOVE_INVENTORY_COMMERCIAL: 'Inventory Commercial (ML)',
    DOVE_INVENTORY_RETAIL: 'Inventory Retail (ML)',
    DOVE_TOTAL_INVENTORY_ML: 'Total Inventory (ML)',
    DOVE_TOP_STOCK_DAYS: 'Total Stock Days',
    DOVE_COMP_PRICE: 'Competitor Price Variance (RM/L)',
    DOVE_SF_VARIANCE: 'Sales Forecast Variance (%)',
    DOVE_FISHERY_C2: 'Fishery – C2',
    DOVE_FISHERY_ABC: 'Fishery – ABC',
    DOVE_TRANSPORT_RIVERINE: 'Transport – Riverine',
    DOVE_TRANSPORT_CARGO: 'Transport – Cargo',
    DOVE_TRANSPORT_PUBLIC: 'Transport – Public',
    DOVE_SHIPMENT_VOL: 'Shipment Volume (ML)',
    DOVE_SHIPMENT_PO: 'Shipment PO Value (RM/L)',
    DOVE_SHIPMENT_EST: 'Shipment Est. GR date',
    DOVE_FORECAST_MAP_RML: 'Forecasted MAP (RM/L)',
    DOVE_ACTUAL_MAP_AFTER_GR: 'Actual MAP after GR (RM/L)',
    DOVE_ICOM: 'ICOM',
    DOVE_Last_updated_on: 'Last updated on ',
    DOVE_Sales_Vol_Ytd: 'Sales Volume (YTD)',
    DOVE_Sales_Vol_Mtd: 'Sales Volume (MTD)',
    DOVE_STOCKOUT_NOTIF: 'PRAI refinery have diesel stock for 15 days only',
    DOVE_FOR_YOUR_ACTION_NOTIF: 'Competeitor price received from sales',
    DOVE_LEFT_PARANTHESIS: '(',
    DOVE_perL_paranthesis: '/L)',
    DOVE_take_action: 'Take Action',
    DOVE_view_details: 'View Details',
    DOVE_INCREASE_LAST_YEAR: 'increase last year',
    DOVE_COMMENTS: 'Comments',
    DOVE_ENTER_COMMENTS: 'Enter your Comments',
    DOVE_RESOLVED: 'Resolved',
    DOVE_BASED_APPROVAL: 'Based on Approval',
    DOVE_SUBMIT: 'Submit',
    DOVE_Etb: 'ETB',
    DOVE_TOTAL_COST_INFO: 'Total cost is the sum of',
    DOVE_DEPOT_OPEX: 'Depot OPEX',
    DOVE_DEPOT_SIDL: 'SIDL - % of MAP',
    DOVE_DEPOT_TRANSIT: 'Transit Loss - % of MAP',
    DOVE_DEPOT_SST: 'SST',
    DOVE_DEPOT_ALPHA: 'ALPHA',
    DOVE_USD: 'USD',
    DOVE_PER_BARREL: '/Barrel',
    DOVE_NEW_ENTRY: 'New Entry',
    DOVE_MARK_AS_COMPLETED: 'Mark as Completed',
    DOVE_MLS_VERSION: 'MLS VERSION',
    DOVE_MLS_DELETE_HEADER: 'Are you sure you want to delete ?',
    DOVE_RETAIL_REVIEW_HEADER: 'Are you sure you want to Submit for Review ?',
    DOVE_RETAIL_APPROVAL_HEADER: 'Are you sure you want to Submit for Approval ?',
    DOVE_EOA_APPROVAL_HEADER: 'Are you sure you want to approve ?',
    DOVE_EOA_REJECT_HEADER: 'Are you sure you want to reject ?',
    DOVE_RETAIL_EDIT_COMMENT_HEADER: 'Edit Justification',
    DOVE_CBD_UNDO_HEADER: 'Are you sure you want to undo?',
    DOVE_MLS_COMMENTS_HEADER: 'Comments for ',
    DOVE_MLS_EDIT_HEADER: 'EDIT SHIPMENT',
    DOVE_MLS_DELETE_CONTENT_1: 'You are about to delete the shipment',
    DOVE_CBD_UNDO_CONTENT_1: 'You are about to undo the price of',
    DOVE_CBD_UNDO_NO: 'No',
    DOVE_CBD_UNDO_YES: 'Yes',
    DOVE_MLS_DELETE_CONTENT_2: 'This operation cannot be undone',
    DOVE_MLS_DELETE_CONTENT_3:
      'You are about to delete the shipment and cancelling full relet shipment',
    DOVE_VIEW_INPUT_DETAILS: 'View input details',
    DOVE_BUTTON_ADD_NEW_SHIPMENT: 'Add new shipment',
    DOVE_MLS_EDIT_ICON: 'Edit shipment',
    DOVE_MLS_DELETE_ICON: 'Delete shipment',
    DOVE_MLS_COMMENTS_ICON: 'View comments',
    DOVE_TRANSACTION_ID: 'Transaction ID',
    DOVE_REASON_FAILURE: 'Reason for failure',
    DOVE_LOG_FILE: 'Rejection reason',
    DOVE_INPUT_FILE_TIMESTAMP: 'Input File timestamp',
    DOVE_SUBMITTED_BY: 'Submitted by',
    DOVE_INITIATION_TIME: 'Initiation Time',
    DOVE_COMPLETION_TIME: 'Completion Time',
    DOVE_VIEW_LOG_FILE: 'View Log File',
    DOVE_MLS_INPUT_DETAILS: 'MLS INPUT DETAILS',
    DOVE_MLS_VERSION_DETAILS: 'MLS Version',
    DOVE_MTD: 'MTD',
    DOVE_YTD: 'YTD',
    DOVE_MONTHLY: 'MONTHLY',
    DOVE_SELECT_DATE: 'Select date',
    DOVE_CLAIMABLE: 'Claimable',
    DOVE_Budget: 'Budget',
    DOVE_ONE_SPOT_HIRE: '0 Spot hire',
    DOVE_Total_Expense: 'Total expenses',
    DOVE_OUTTURN_LOSS_PERCENTAGE: 'Outturn Loss (%)',
    DOVE_CLAIMABLE_AMOUNT: 'Claimable Amount (RM)',
    DOVE_OUTTURN_LOSS_HEADING: 'OUTTURN LOSS',
    DOVE_PPM: 'PPM ',
    DOVE_NO_DATA_ALERT: 'No alerts at this time. Be a go better, and check back soon.',
    DOVE_NO_DATA_VESSEL: 'Nothing due today. Check back soon',
    DOVE_NO_DATA_BERTH: 'Currently there is no vessel in this berth',
    DOVE_NO_DATA_COMMON: 'No data for today. Check back soon.',
    DOVE_View_Details: 'View Details',
    DOVE_Menu: 'Menu',
    DOVE_VR_Value_Error: 'Enter a VR Value ',
    DOVE_MLS_Volume_Validation: 'Please enter a value greater than zero.',
    DOVE_FORM_VALIDATION_REQUIRED: 'Required',
    DOVE_FORM_VALIDATION_NUMBER_ONLY: 'Must be a number',
    DOVE_FORM_VALIDATION_CHARACTER_ONLY: 'Only characters allowed',
    DOVE_FORM_VALIDATION_NO_SPECIAL_CHARACTERS: 'Only alphanumeric characters allowed',
    DOVE_FORM_VALIDATION_SOME_SPECIAL_CHARACTERS: 'Entered special character is not allowed',
    DOVE_FORM_VALIDATION_MAX_LENGTH: 'Must be 200 characters or less',
    DOVE_FORM_VALIDATION_INVALID_EMAIL: 'Invalid email address',
    DOVE_ALERTS: 'ALERTS',
    DOVE_TOTAL_STOCK_DAYS: 'Total Stock Days',
    DOVE_TOTAL_INV_BULK_AVIATION: 'Inventory (Aviation & Bulk) (ML)',
    DOVE_q88: 'Q88',
    DOVE_stowageplan: 'Stowage Plan',
    DOVE_COMPETITOR_RETAIL_PRICE_HEADER: 'COMPETITOR RETAIL PRICE ANALYSIS',
    DOVE_VERSION: 'Buid version',
    DOVE_VERSION_DATE: 'Last successful build',
    DOVE_USER_AGENT: 'User agent',
    DOVE_Depot: 'DEPOT',
    DOVE_VI_SHIPPING_AGENT: 'SHIP AGENT',
    DOVE_LIFTED_SO_FAR: 'Lifted so far',
    DOVE_NOMINATION: 'Nomination',
    DOVE_Disport_Label: 'Disport',
    DOVE_Destination_Label: 'Destination',
    DOVE_ACCOUNT_NUMBER: 'Account number',
    DOVE_ACCOUNT_NAME: 'Account name',
    DOVE_SALES_EXECUTIVE_NAME: 'Sales Executive',
    DOVE_MLS_Doc_Validation: 'Please enter a valid D/O No.',
    DOVE_MLS_Voyage_Validation: 'Please select a valid D/O No.',
    DOVE_UNDERCONSTRUCTION: 'We are working on this. Come back again.',
    DOVE_INVENTORY_HEADER: 'INVENTORY',
    DOVE_PRODUCT_NOT_SOLD: 'Product is not available in this depot.',
    DOVE_SCATTERPLOT_HEADER: 'STATION PROFILE',
    DOVE_AT: ' at ',
    DOVE_MYT: ' (MYT)',
    DOVE_EXPORT: 'Export',
    DOVE_SHIP_REF: 'Shipment Ref : ',
    DOVE_ADD_REMARK: 'Add remark',
    DOVE_ADD: 'Add',
    DOVE_ENTER_REMARKS: 'Enter remarks',
    DOVE_PREVIOUS_REMARKS: 'All remarks',
    DOVE_VIEW_All_REMARKS: 'View all remarks',
    DOVE_VESSEL_STATUS: 'Vessel status:',
    DOVE_PRICE_UPDATE_HISTORY: 'Price update history',
    DOVE_PRICE_PROPOSAL_HISTORY: 'Price proposal history',
    DOVE_MDF_HISTORICAL_TRENDS: 'MDF historical trends',
    DOVE_CONTACT: 'Please contact',
    DOVE_EXCHANGE_RATE: 'Exchange rate',
    DOVE_SELECT_DATE_TIME: 'Select date and time',
    DOVE_NO_RESULTS_FOUND: 'No results found!',
    DOVE_SEARCH_ERROR_MESSAGE: "We can't find any items matching your search.",
    DOVE_NEXT_REPLENISHMENT: 'Next replenishments',
    DOVE_ATG_HEADER: 'Inventory overview',
    DOVE_VESSEL_ETA: 'ETA - ',
    DOVE_VESSEL_ETB: 'ETB - ',
    DOVE_VESSEL_ETC: 'ETC - ',
    DOVE_VESSEL_ETD: 'ETD - ',
    DOVE_Start_date: 'Start date - ',
    DOVE_End_date: 'End date - ',
    DOVE_Forecast_margin_of_total_cost: 'Proposed margin of forecasted total cost',
    DOVE_DEMAND_PLANNER: 'DOVE-Demand Planner',
    DOVE_PDS: 'DOVE-Primary Distribution Scheduler',
    DOVE_MELAKA_PLANNER: 'DOVE-Melaka Planner',
    DOVE_VIEW_AS: 'View as',
    DOVE_VesselMPP: 'Vessel/MPP',
    DOVE_OPEN: 'Open',
    DOVE_CLOSE: 'Close',
    DOVE_REFERENCE_NUM: 'Nomination ref.',
    DOVE_PO_NUM: 'PO Number',
    DOVE_Timely_Arrival: 'Vessel timely arrival (MTD)',
    DOVE_File_Source: 'Source',
    DOVE_ETC: 'ETC',
    DOVE_ETB: 'ETB',
    DOVE_ETA: 'ETA',
    DOVE_Laycan_Arrival: 'Arrival within laycan (MTD)',
    DOVE_Select_month: 'Select month',
    DOVE_Port: 'Port',
    DOVE_Product_Outturn: 'Product',
    DOVE_LoadedVol: 'Loaded vol',
    DOVE_OVERALL_TRANSIT_LOSS_PERCENTAGE: 'Overall transit loss (%)',
    DOVE_OUTTURN_VOL: 'Outturn vol',
    DOVE_Select_year: 'Select year',
    DOVE_LITRE_UNIT: '(L)',
    DOVE_Destination_camelCase: 'Destination',
    DOVE_New_IDRS_cost: 'Set new reseller and additive cost',
    DOVE_Note: 'Note:',
    DOVE_Cbd_Idrs_Note:
      ' It is mandatory to submit all depot’s price if current reseller/additive cost is changed.',
    DOVE_Curr_Reseller_Cost: 'Current reseller cost',
    DOVE_Recent_Updates: 'Recent updates',
    DOVE_SURVEYOR_EFFICIENCY: 'Surveyor Efficiency % (MTD)',
    DOVE_SHIPMENTS_UNDER: 'Shipments Under ',
    DOVE_D_L_Number: 'D/L No. PDB',
    DOVE_VESSEL_NAME: 'Vessel',
    DOVE_PORT_NAME: 'Port',
    DOVE_BASELINE: 'Baseline (Hrs)',
    DOVE_ACTUAL: 'Actual (Hrs)',
    DOVE_IMPROVEMENT: 'Improvement %',
    DOVE_BILLOF_LADING: 'Bill of lading date',
    DOVE_MDF_PERIOD: 'Period',
    DOVE_MDF_VERSION: 'Version',
    DOVE_MDF_UPDATE_DETAILS: 'MDF Update Details',
    DOVE_MOPS_UPDATE_DETAILS: 'MOPS Update Details',
    DOVE_MDF_STATUS: 'Status',
    DOVE_MDF_ACTION: 'Action',
    DOVE_MDF_ARCHIVES_HEADING: 'MONTHLY DEMAND FORECAST ARCHIVES',
    DOVE_MDF_ARCHIVES_LASTTWELVE_DOWNLOAD: "Download last 12 month's MDF",
    DOVE_INVENTORY_DOWNLOAD: 'Inventory Report',
    DOVE_MDF_ARCHIVES_BULKALL_DOWNLOAD: 'Download all Versions',
    DOVE_Button_UploadMDF: 'Upload MDF',
    DOVE_Button_Archives: 'Archives',
    DOVE_Button_Download_Usermanual: 'User Manual',
    DOVE_Button_PublishApprovedVersion: 'Publish approved version',
    DOVE_Button_Export_as_excel_MDF: 'Export as Excel',
    DOVE_Button_PowerBI_MDF: 'Power BI Analysis',
    DOVE_Button_Download_MDF: 'Download MDF',
    DOVE_Button_Upload_MDF: 'Upload MDF',
    DOVE_AGENTS_CARDHEADER: 'AGENTS',
    DOVE_SURVEYORS_CARDHEADER: 'SURVEYORS',
    DOVE_SUPA_CARDHEADER: 'SUPA',
    DOVE_SUPA_EFFICIENCY: 'SUNGAI UDANG PORT AUTHORITY (SUPA) EFFICIENCY MONITORING',
    DOVE_AREA4B_CARDHEADER: 'MRC AREA 4B',
    DOVE_OIL_ACCOUNTING: 'OIL ACCOUNTING',
    DOVE_SCHEDULER_CARDHEADER: 'SCHEDULER',
    DOVE_OVERALL_EFFICIENCY_VESSELS: 'OVERALL EFFICIENCY & SHIPMENT DETAILS',
    DOVE_BERTH_EFFCIENCY_PAGE_TITLE: 'MRCSB BERTH EFFICIENCY MONITORING',
    DOVE_Perfomance: 'Performance',
    DOVE_Cost_saving: 'Cost saving',
    DOVE_Total_cost_saving: 'Total cost saving',
    DOVE_Overall_perfomance: 'Overall performance',
    DOVE_Avg_time_taken: 'Average time taken',
    DOVE_AGENT_LABEL: 'Agent',
    DOVE_BACKLOADING: 'MLS shipment for backloading',
    DOVE_SURVEYOR_LABEL: 'Surveyor',
    DOVE_New_RS_cost: 'Set new reseller cost',
    DOVE_Cbd_RS_Note:
      ' It is mandatory to submit all depot’s price if current reseller cost is changed.',
    DOVE_FILTER: 'Filter',
    DOVE_By_User: 'By User',
    DOVE_By_Model: 'By Model',
    DOVE_C12: 'C12',
    DOVE_C14: 'C14',
    DOVE_C50: 'C50',
    DOVE_Bulk: 'Bulk',
    DOVE_BulkOC: 'Bulk OC',
    DOVE_Data_Not_Changed: 'Enter a value different from the previous value.',
    DOVE_Error: 'Error:',
    DOVE_Vessel_Name_Icom: 'Vessel Name',
    DOVE_CANCELLED_MLS: 'CANCELLED SHIPMENTS',
    DOVE_PLANNED_VALUE: 'Planned value',
    DOVE_BASE_VALUE: 'Base value',
    DOVE_UPDATED_BY: 'Updated by',
    DOVE_ERROR_REQUIRED: '*Required',
    DOVE_ERROR_MAX_CHAR_255: 'Max allowed character length is 255',
    DOVE_Edit_MDF: 'EDIT VALUES',
    DOVE_CBD: 'CBD',
    DOVE_RBD: 'RBD',
    DOVE_DEPOT_ID: 'Depot Id',
    DOVE_DEPOT_Name: 'Depot name',
    DOVE_MLS_SURVEYOR_EFFICIENCY: 'SURVEYOR EFFICIENCY MONITORING',
    DOVE_MLS_SURVEYOR_OVERALL_PERFORMANCE: 'Overall performance',
    DOVE_MLS_SURVEYOR_TOTAL_SAVING: 'Total cost saving',
    DOVE_MLS_SURVEYOR_COMPANY: 'Surveyor company',
    DOVE_MLS_SURVEYOR_PRFORMANCE_LABEL: 'Performance',
    DOVE_MLS_SURVEYOR_SHIPMENTS_TITLE: 'Shipments completed',
    DOVE_MLS_SURVEYOR_SHIPMENT_NUMBER: 'D/O No. PDB',
    DOVE_MLS_SURVEYOR_SHIPMENT_NAME: 'Surveyor name',
    DOVE_MLS_SURVEYOR_SHIPMENT_BASELINE: 'Baseline',
    DOVE_MLS_SURVEYOR_VESSEL_NAME: 'Vessel name',
    DOVE_MLS_SURVEYOR_VESSEL_NAME2: 'Customer',
    DOVE_MLS_SURVEYOR_AGENT_NAME: 'Agent name',
    DOVE_MLS_SURVEYOR_ACTUAL: 'Actual',
    DOVE_MLS_SURVEYOR_PARCEL: 'Parcel (Nominated)',
    DOVE_MLS_SURVEYOR_COST_SAVING: 'Cost savings',
    DOVE_MLS_SURVEYOR_SHIPMENT_PERFORMANCE: 'Performance',
    DOVE_MLS_AGENT_COMPANY: 'Agent company',
    DOVE_SURVEYOR_ACTIVITY_BREAKDOWN_TITLE: 'Surveyor activity breakdown',
    DOVE_SUPA_ACTIVITY_BREAKDOWN_TITLE: 'SUPA activity breakdown',
    DOVE_AGENTS_ACTIVITY_BREAKDOWN_TITLE: 'Agent activity breakdown',
    DOVE_SCHEDULER_ACTIVITY_BREAKDOWN_TITLE: 'Scheduler activity breakdown',
    DOVE_OILACC_ACTIVITY_BREAKDOWN_TITLE: 'MRC Oil Accounting activity breakdown',
    DOVE_AREA4B_ACTIVITY_BREAKDOWN_TITLE: 'MRC AREA 4B activity breakdown',
    DOVE_MLS_SCHEDULER_EFFICIENCY: 'SCHEDULER EFFICIENCY MONITORING',
    DOVE_MLS_OILACC_EFFICIENCY: 'MRC OIL ACCOUNTING EFFICIENCY MONITORING',
    DOVE_MLS_AGENT_EFFICIENCY: 'AGENT EFFICIENCY MONITORING',
    DOVE_MLS_AREA4B_EFFICIENCY: 'MRC AREA 4B EFFICIENCY MONITORING',
    DOVE_MLS_SCHEDULER_OVERALL_PERFORMANCE: 'Overall performance',
    DOVE_PUBLISHED_BY: 'Published by',
    DOVE_Cbd_No: 'No',
    DOVE_Cbd_OK: 'OK',
    DOVE_Cbd_LOA_Error:
      'One or more depots fall under different LOA approver level, please check and populate them manually.',
    DOVE_Cbd_LOA_Error_Reviewer:
      'One or more depots fall under different LOA reviewer level, please check and populate them manually.',
    DOVE_MOPS_UPDATED_ON: 'MOPS - updated on',
    DOVE_VIEW_NEW_MDF: 'View new MDF',
    DOVE_MDF_Updates: 'MDF Updates',
    DOVE_PUBLISH_PROMPT1: 'You are about to publish the monthly demand forecast,',
    DOVE_PUBLISH_PROMPT2: 'Once published, all business divisions can view this version.',
    DOVE_ARE_YOU_SURE: 'Are you sure you want to publish?',
    DOVE_YES_PUBLISH: 'Yes, Publish',
    DOVE_X_CANCEL: 'X Cancel',
    DOVE_VERSION_PUBLISH: 'version :',
    DOVE_PDB_MARGIN: 'PDB MARGIN',
    DOVE_PTLCL_MARGIN: 'PTLCL MARGIN',
    DOVE_PPTSB_MARGIN: 'PPTSB COSTING',
    DOVE_MRCSB_MARGIN: 'MRCSB COSTING',
    DOVE_REVENUE_UPPERCASE: 'REVENUE',
    DOVE_COST_UPPERCASE: 'COST',
    DOVE_MARGIN_UPPERCASE: 'MARGIN',
    DOVE_COPY_MDF_PUBLISH:
      'A new version of MDF is available. Please save the edited values if any. Your changes will be copied to the latest version.',
    DOVE_OK: 'OK',
    DOVE_MLS_ADD_HEADER: 'ADD NEW SHIPMENT',
    DOVE_MLS_PUBLISH_CONTENT1: 'You are about to publish the monthly lifting schedule, version ',
    DOVE_MLS_PUBLISH_CONTENT2: 'Once published, Melaka planner will be viewing this version.',
    DOVE_MLS_ADD_NEW_PRODUCT: 'Add new product',
    DOVE_MLS_ADD_NEW_DISCHARGEPORT: 'Add new discharge port',
    DOVE_MLS_LDR_Validation: 'Please select a vaild start and end date.',
    DOVE_MLS_VESSEL_Validation: 'Please select a vessel.',
    DOVE_MLS_LOADPORT_Validation: 'Please select a valid load port.',
    DOVE_MLS_Product_Validation: 'Please select a valid product.',
    DOVE_MLS_Product_Add_Validation: 'Please select atleast 1 product.',
    DOVE_MLS_DischargePort_Add_Validation: 'Please select atleast 1 discharge port.',
    DOVE_MLS_DischargePort_Validation: 'Please select a discharge port.',
    DOVE_MLS_DischargePortOTHER_Validation: 'Please enter a discharge port name.',
    DOVE_MLS_Volume_Max_Validation: 'Please enter a value less than 200 KB.',
    DOVE_OOC_PREMIUM: 'OIL COMPANIES PREMIUM',
    DOVE_VOYAGE_HEADING: 'Voyage',
    DOVE_VOLUME_MT: 'Volume ( MT )',
    DOVE_VOYAGE_NUMBER_REQUIRED: 'Please enter a valid voyage number',
    DOVE_VOYAGE_NUMBER_UNIQUE: 'Voyage no already  exists',
    DOVE_VESSEL_NAME_REQD: 'Please enter a valid vessel name',
    DOVE_LDR_REQD: 'Please enter valid LDR dates',
    DOVE_LOADPORT_REQD: 'Please enter valid load port',
    DOVE_VOLUME_REQD: 'Please enter volume value (Max Limit: 10000MT)',
    DOVE_TOTAL_VOLUME: 'Total Volume',
    DOVE_DDR_REQD: 'Please enter valid DDR dates',
    DOVE_ETA_REQD: 'Please enter valid ETA dates',
    DOVE_DDR_VALIDATION: 'Invalid date. DDR start cannot be earlier than LDR start date.',
    DOVE_ETA_VALIDATION: 'Invalid date time. ETA date time cannot be earlier than LDR start date.',
    DOVE_NO_SHIPMENT: 'Currently there is no shipment added',
    DOVE_VOL_MT: 'Volume ( MT )',
    DOVE_MONTH: 'Month',
    DOVE_IM: 'Integrated margin (RM)',
    DOVE_YTD_IM: 'YTD integrated margin (RM)',
    DOVE_RV: 'Retail volume (L)',
    DOVE_YTD_RV: 'YTD retail volume (L)',
    DOVE_AMV: 'ACTUAL MARGIN AND VOLUME',
    DOVE_LDR_START: 'LDR start date should be within selected month',
    DOVE_VESSEL_SIMULATION: 'Vessel Simulation',
    DOVE_VESSEL_SIMULATION_HEADER: 'VESSEL SIMULATION',
    DOVE_DRAG_FILE: 'Drag and drop your files here',
    DOVE_BROWSE: 'Browse',
    DOVE_OR: 'or',
    DOVE_SIMULATOR_PLACEHOLDER_TEXT: 'Please click on ‘simulate’ button and view the output here.',
    DOVE_FORMAT_ERROR: 'Invalid file format. Please upload .xlsx file.',
    DOVE_SIMULATOR_FORMAT_ERROR:
      'Unsuccessful attempt to upload simulation input file due to format validation check failure. Please provide a valid file.',
    DOVE_FILENAME_ERROR: 'Invalid file name.',
    DOVE_STOP_TIME: 'Stop Time',
    DOVE_SUGGESTED_MLS_LIST_BTN: 'Suggested MLS shipments for backloading',
    DOVE_SUGGESTED_MLS_LIST_HEADER: 'SUGGESTED MLS SHIPMENTS FOR BACKLOADING',
    DOVE_CAPACITY: 'Capacity (MT)',
    DOVE_FINAL_DICHARGE_PORT: 'Final discharge port',
    DOVE_VOYAGE_NO_PCG: 'Voyage No. PCG',
    DOVE_MLS_SHIPMENTS_BACKLOADED: 'Value Realized from Backloading, Partial Relet and Full Relet',
    DOVE_MLS_NO_SHIPMENTS_BACKLOADED: 'No of Backloaded, Partial and Full Relet Shipment',
    DOVE_MANUAL_UPDATE_TEXT: '(Manually uploaded KPI)',
    DOVE_Hrs: ' Hrs',
    DOVE_Idling: 'Idling time',
    DOVE_Total_Idling: ' Total Idling time',
    DOVE_DDR_START: 'DDR start date should be within selected month',
    DOVE_IMPROVEMENT_BASELINE: 'Improvement from baseline',
    DOVE_OVERALL_IMPROVEMENT: 'Overall improvement from baseline',
    DOVE_COASTAL_BERTH_EFFICIENCY: 'OVERALL COASTAL BERTH (LOADING) EFFICIENCY',
    DOVE_AVG_TOTAL_TIME_SPENT: 'Average total time spent',
    DOVE_STOP_TIME_ERROR: 'Please enter stop time',
    DOVE_Ship_Ref: 'Shipment ref. no.',
    DOVE_BASICINFO: 'Basic Info',
    DOVE_CURRENT_VOYAGE_PLAN: 'Current voyage plan',
    DOVE_ADDITIONALINFO: 'Additional Info',
    DOVE_Laden: 'Laden',
    DOVE_Ballast: 'Ballast',
    DOVE_NO_VOYAGE: 'No voyage data available',
    DOVE_BUNKERCOST: 'Bunker cost',
    DOVE_BUNKERLEVEL: 'Bunker level',
    DOVE_Idle: 'Idle',
    DOVE_VESSEL_OPERATIONS: 'VESSEL OPERATIONS',
    DOVE_COASTAL_BERTH_DISCHARGING: 'OVERALL OCEAN BERTH (DISCHARGING) EFFICIENCY',
    DOVE_COASTAL_BERTH_LOADING: 'OVERALL OCEAN BERTH (LOADING) EFFICIENCY',
    DOVE_PPM_NUM_REQUIRED: 'Please select a valid mls number',
    DOVE_MLS_BACKLOADING_UNIQUE: 'D/O no. already utilized',
    DOVE_BACKLOADED_HEADER: 'MLS SHIPMENTS BACKLOADED & RELET',
    DOVE_BACKLOADED_VOYAGE_NO: 'Voyage No.',
    DOVE_BACKLOAD_VOYAGE_NO: 'Voyage No.',
    DOVE_BACKLOADED_PRODUCT: 'Product',
    DOVE_BACKLOADED_VOLUME: 'Volume ( MT )',
    DOVE_VALUE_REALIZED: 'Value realised ( USD )',
    DOVE_PROCEED: 'Yes, Proceed',
    DOVE_ERROR_NOT_KUANTAN: 'Please select load port as Kuantan as backloading is initiated',
    DOVE_NOTVALID_BACKLOADING: 'Backloading is no longer viable. Please replan',
    DOVE_Shipment_Ref_No: 'Shipment Ref No.',
    DOVE_Avg_Time: 'Average time spent',
    DOVE_CUSTOMER_PERFORMANCE: 'CUSTOMER PERFORMANCE',
    DOVE_Bunker_consumption_pdb: 'Bunker consumption (shipment)',
    DOVE_SUGGESTEDMLS_RELET: 'Suggested MLS shipments for relet',
    DOVE_Upload_MDF_Error: 'Invalid file format. Please upload .xlsx file.',
    DOVE_PLI_Product_LABEL: 'Product(s)',
    DOVE_PLI_Volume_LABEL: 'Volume(MT)',
    DOVE_No_data_available: 'No data available',
    DOVE_Total_estimated_demurrage: 'Total estimated demurrage',
    DOVE_Charter_hire_rate_not_available: 'Charter hire rate not available',
    DOVE_Demurrage_rate_not_available: 'Demurrage rate not available',
    DOVE_AVAILABLE_CAPACITY: 'Available Capacity (MT)',
    DOVE_SUGGESTED_MLS_RELET: 'SUGGESTED MLS SHIPMENTS FOR RELET',
    DOVE_Shipment_Partial_Relet: 'MLS shipment for partial relet',
    DOVE_PLI_DPORT: 'PLI discharge port',
    DOVE_MLS_RELET: 'No of relet shipments',
    DOVE_VALUE_REALISED: 'Value realised from relet',
    DOVE_SHIPMENT_TYPE: 'Type',
    DOVE_Please_update_data_in_iMOS: 'Please update data in iMOS',
    DOVE_Previous_shipment: 'Previous shipment',
    DOVE_Upcoming_shipment: 'Upcoming shipment',
    DOVE_Available_date_range: 'Available date range',
    DOVE_Request_relet: 'Request relet',
    DOVE_Loading_port: 'Loading port',
    DOVE_Full_relet: 'Full relet',
    DOVE_Partial_relet: 'Partial relet',
    DOVE_Submit_request: 'Submit request',
    DOVE_PLI_create_label: 'Check this to create a fully relet shipment for PLI',
    DOVE_PCG_create_label: 'Check this to create a fully relet shipment for PCG',
    // Bunker consumption
    DOVE_BUNKER_STAGE: 'Stage',
    DOVE_BUNKER_STATUS: 'Status',
    DOVE_BUNKER_SPEED: 'Speed',
    DOVE_BUNKER_CONSUMPTION: 'Bunker consumption',
    DOVE_BUNKER_CP_CONSUMTION: 'CP Consumption',
    DOVE_AGREED_SPEED: 'Agreed speed in CP',
    DOVE_OB_PLI_BASEOIL: 'OVERALL OCEAN BERTH ( PLI LOADING) EFFICIENCY',
    DOVE_COASTAL_PCG_DISCHARGING: 'OVERALL COASTAL BERTH (PCG DISCHARGING) EFFICIENCY',
    DOVE_Confirm_PLI_ADD_MLS: 'Confirm to proceed with PLI full re-let for Voyage No : ',
    DOVE_Confirm_PLI_Cancel: 'You are about to cancel full relet shipment : ',
    DOVE_EMPTY_FILE_SELECT_ERROR: 'Please select file',
    DOVE_NO_BUNKER: 'No bunker information available',
    DOVE_SHIPMENT_COUNT: 'Shipment details',
    DOVE_RELET_HEADER: 'MLS SHIPMENTS RELET',
    DOVE_NOTVALID_RELET: 'Relet is no longer viable. Please replan',
    DOVE_FPSIA: 'FPSIA ref.',
    DOVE_CB_PLI_BASEOIL: 'OVERALL COASTAL BERTH (PLI LOADING) EFFICIENCY',
    DOVE_ALLOWABLE_LAYCAN: 'Allowable laytime ( hrs )',
    DOVE_ALLOWABLE_LAYCAN_ERROR: 'Allowable laytime cannot be more than 10,000',
    DOVE_DEMUURAGE_ERROR: 'Demurrage rate should be less than 1,000,000',
    DOVE_DEMURRAGE_RATE: 'Demurrage rate ( USD )',
    DOVE_OTHER_DISCHARGE_PORT: 'Other discharge port',
    DOVE_OTHER_DISCHARGE_PORT_error: 'Please enter a discharge port name',
    DOVE_Revenue: 'Revenue',
    DOVE_Expenses: 'Expenses',
    DOVE_CPP: 'CPP',
    DOVE_DPP: 'DPP',
    DOVE_INVENTORY_TREND: 'PETCO INVENTORY TREND',
    DOVE_PETCO_DEVIATION: 'DEVIATION',
    DOVE_PETCO_DATA: 'Showing data for PETCO',
    DOVE_Please_update_data_PLIScheduling_screen: 'Please update data in PLI Scheduling screen',
    DOVE_LNC_PNL: 'LNC PNL',
    DOVE_TotalRevenue: 'Total revenue',
    DOVE_TotalExpenses: 'Total expenses',
    DOVE_Please_update_data_PCGScheduling_screen: 'Please update data in PCG Scheduling screen',
    DOVE_DDP_KPI_NAME: 'KPI Name',
    DOVE_DDP_KPI_EPIC: 'Epic',
    DOVE_DDP_KPI_FEATURE: 'Feature Descriptions',
    DOVE_DDP_KPI_KEY_ATTRIBUTES: 'Key Attributes',
    DOVE_KPI_DISABLE: 'Disable',
    DOVE_KPI_VIEW: 'View',
    DOVE_KPI_ENABLE: 'Enable',
    DOVE_KEEP: 'No, Cancel',
    DOVE_YES_DISABLE: 'Yes, Disable',
    DOVE_DISABLE_KPI: 'DISABLE KPI',
    DOVE_DISABLE_CONFIRM: 'Are you sure about disabling this kpi',
    DOVE_ATTRIBUTE_NAME: 'Atrribute Name',
    DOVE_CURRENT_VALUE: 'Current Value',
    DOVE_CURRENT_EFFECTIVE_DATE: 'Current Effective Date',
    DOVE_NEW_VALUE: 'New Value',
    DOVE_NEW_EFFECTIVE_DATE: 'New Effective Date',
    DOVE_KPI_HEADER: 'KPI MANAGEMENT',
    DOVE_STATUS_INFO: 'Sort by status',
    DOVE_Discharge_Daterange: 'Discharge date range',
    DOVE_ALLOWABLE_LAYTIME: 'Allowable laytime ( Hrs )',
    DOVE_VALUE_REALISED_VALUE: 'VR Value (USD)',
    DOVE_DEMURRAGE: 'Demurrage rate ( USD/ day )',
    DOVE_PETCO_INVENTORY: 'PETCO INVENTORY',
    DOVE_PETCO_MDF: 'MONTHLY DEMAND FORECAST - SUMMARY',
    DOVE_LAYTIME_NOT_AVAILABLE: 'Allowed laytime is not available',
    DOVE_EMPTY_HEADER: '',
    DOVE_PCG_DOWNLOAD: 'Download schedule',
    DOVE_DEMURRAGE_SOURCE: 'Estimated demurrage rate by PETCO LNC',
    DOVE_DISCHARGED_VOL: 'Discharged vol',
    DOVE_DEPARTED_DISCHARGING: 'List of departed vessels from Melaka after discharging',
    DOVE_DEPARTED_LOADING: 'List of departed vessels from Melaka after loading',
    DOVE_PLI_DOWNLOAD: 'Download schedule',
    DOVE_MDO_VOLUME_INFO: 'This includes MDO volume',
    DOVE_MFO_VLF_VOLUME_INFO: 'MFO value includes VLF volume',
    DOVE_MDO_MGO_VOLUME_INFO: 'MGO value includes MDO volume',
    DOVE_MDO_VLF_VOLUME_INFO: 'MFO includes VLF and MGO includes MDO volume',
    DOVE_GPP_PRICE_UPDATE: 'Please provide reason for Price Update',
    DOVE_NOM_CB_LOADING: 'PETCO NOM COASTAL BERTH (LOADING) EFFICIENCY',
    DOVE_NOM_OB_LOADING: 'PETCO NOM OCEAN BERTH (LOADING) EFFICIENCY',
    DOVE_Target: 'Target',
    DOVE_SELECT_GRAPH_RANGE: 'Select graph range',
    // VR Dashboard
    DOVE_PDB_CB_EFF: 'PDB Coastal Berth Efficiency',
    DOVE_NOM_CB_EFF: 'NOM Coastal Berth Efficiency',
    DOVE_NOM_OB_EFF: 'NOM Ocean Berth Efficiency',
    DOVE_AFFRAMAX_TC: 'Aframax TC Time Utilization Improvement',
    DOVE_FOB: 'Demurrage reduction ( FOB Vessel )',
    DOVE_VIEW_DETAILS: 'View more details',
    DOVE_VR_BASELINE: 'Baseline',
    DOVE_VR_TARGET: 'Target  ',
    DOVE_VR_ACTUAL: 'Actual  ',
    DOVE_VR_TIMEREDUCTION: 'Time reduction from baseline',
    DOVE_VR_REALISEDVALUE: 'Realised value for ',
    DOVE_VR_YTDVALUE: 'YTD Realised value',
    DOVE_VR_HEADER: 'Value realised from',
    DOVE_VR_TO: 'to',
    DOVE_VR_JAN: 'Jan',
    DOVE_VR_FEB: 'Feb',
    DOVE_VR_MAR: 'Mar',
    DOVE_VR_APR: 'Apr',
    DOVE_VR_MAY: 'May',
    DOVE_VR_JUN: 'Jun',
    DOVE_VR_JUL: 'Jul',
    DOVE_VR_AUG: 'Aug',
    DOVE_VR_SEP: 'Sep',
    DOVE_VR_OCT: 'Oct',
    DOVE_VR_NOV: 'Nov',
    DOVE_VR_DEC: 'Dec',
    DOVE_CLICK_ON_MONTH: 'Click on each month to see the berth efficiency details',
    DOVE_PLI_RELET_SHIP: 'PLI Relet Shipment Details',
    DOVE_PLI_MTD_PARTIAL_RELET: 'Total MTD Partial Relet Shipments',
    DOVE_VR_TOTAL_YTD_RELET: 'Total YTD Shipment Relets',
    DOVE_VR_YTD_FULL_RELET: 'YTD Full Relets',
    DOVE_VR_YTD_PARTIAL_RELET: 'YTD Partial Relets',
    DOVE_VR_YTD_PARTIAL_RELET_SHIPMENTS: 'Total YTD Partial Relet Shipments',
    DOVE_VR_YTD_VALUE_REALISED: 'Total YTD Value Realised',
    DOVE_VR_MTD_SHIP_RELET: 'MTD Shipment Relets',
    DOVE_VR_TOTAL_YTD_NOM_PARTIAL_RELET_SHIPMENTS: 'Total YTD NOM Partial Relet Shipments',
    DOVE_VR_MTD_FULL_RELET: 'MTD Full Relets',
    DOVE_VR_MTD_PARTIAL_RELET: 'MTD Partial Relets',
    DOVE_VR_MTD_VALUE_REALISED: 'Total MTD Value Realised',
    DOVE_VR_MONTH: 'Month',
    DOVE_VR_MTD_NOM_PARTIAL_RELET_SHIPMENTS: 'Total MTD NOM Partial Relet Shipments',
    DOVE_VR_VALUE_REALISED: 'Value Realised',
    DOVE_VR_NO_FULL_RELET: 'No. of Full Relet',
    DOVE_VR_NO_PARTIAL_RELET: 'No. of Partial Relet',
    DOVE_VR_TOTAL_YTD_BACKLOADED: 'Total YTD Backloaded Shipments',
    DOVE_VR_TOTAL_MTD_BACKLOADED: 'MTD Backloaded Shipments',
    DOVE_VR_TOTAL_YTD_VR: 'Total YTD Value Realised',
    DOVE_VR_TOTAL_MTD_VR: 'MTD Value Realised',
    DOVE_VR_NO_SHIPMENTS: 'No. of Shipments',
    DOVE_VR_BUNKER_OPTIMIZATION: 'Bunker Optimization Details',
    DOVE_VR_YTD_TOTAL_QUANTITY_SAVINGS: 'YTD Total Quantity Savings',
    DOVE_VR_YTD_BUNKER_COST_SAVINGS: 'YTD Bunker Cost Savings',
    DOVE_VR_MTD_TOTAL_QUANTITY_SAVINGS: 'MTD Total Quantity Savings',
    DOVE_VR_MTD_BUNKER_COST_SAVINGS: 'MTD Bunker Cost Savings',
    DOVE_VR_QUANTITY_SAVINGS: 'Quantity Savings',
    DOVE_VR_COST_SAVINGS: 'Cost Savings',
    DOVE_PETCO_PHYSICAL_INVENTORY: 'PHYSICAL INVENTORY - ',
    DOVE_PETCO_AMBIENT_TEMPERATURE: '(Volume at ambient temperature)',
    DOVE_DEPOT_INVENTORY: 'DEPOT INVENTORY',
    DOVE_DAILY_TANK_INVENTORY: 'DEPOT DAILY TANK INVENTORY',
    DOVE_PETCO_BOOK_INVENTORY: 'BOOK INVENTORY - ',
    DOVE_PLANNED_DISCHARGE: 'Parcel ( Planned Discharge )',
    DOVE_PLANNED_LOADING: 'Planned loading',
    DOVE_LOADED_VOLUME: 'Loaded volume',
    DOVE_PETCO_VOY_NO: 'PETCO Voyage No.',
    DOVE_PORT_ORDER: 'Port Order',
    DOVE_MLS_TABLE_HEADER_PLANNED_LOADING: 'Planned loading',
    DOVE_PLAN_REVISED: 'Plan revised on',
    DOVE_FIXED: 'Fixed',
    DOVE_REVISED: 'Revised',
    DOVE_DIFFERENCE: 'Difference',
    DOVE_DOWNLOAD_LOG_FILE: 'Download Log File',
    DOVE_SIX_AM: ', 06:00 AM',
    DOVE_MLS_TABLE_HEADER_VR_VALUE: 'VR Value (USD)',
    DOVE_BACKLOADING_SUGGESTIONS: 'Backloading suggestions',
    DOVE_Full_RELET_SUGGESTIONS: 'Full relet suggestions',
    DOVE_VOYAGE_TYPE: 'Type',
    DOVE_SUGGESTED_MLS_LIST_HEADER2: 'SUGGESTED MLS SHIPMENTS FOR BACKLOADING AND RELET',
    DOVE_VR_VALUE: 'Please enter vr value.',
    DOVE_Confirm_PCG_ADD_MLS: 'Confirm to proceed with PCG full re-let for Voyage No : ',
    DOVE_PENALTY_TRACKER: 'LIFTING & PENALTY TRACKER',
    DOVE_MASTER_FILE: 'INTERNAL MASTER FILE MANAGEMENT',
    DOVE_REVIEW_PUBLISH: 'Review and publish',
    DOVE_DEPOT_ALERTS: 'Depot Alerts',
    DOVE_VOYAGE_NO: 'Voyage No: ',
    DOVE_Etd: 'ETD',
    DOVE_INV_ACTIVE_INVENTORY: 'Active Inventory',
    DOVE_INV_DATE_STOCKOUT: 'Date of stock out',
    DOVE_INV_DATE_REPLENISHMENT: 'Replenishment date',
    DOVE_INV_VOLUMN_REPLENISHMENT: 'Replenishment volume',
    DOVE_THIRD_PARTY: '3rd Party Shipments',
    DOVE_NOM_SHIP: 'DOVE NOM Shipments',
    DOVE_CONFIRMATION: 'Confirmation to submit ',
    DOVE_MLS_EDIT_AUTOGR: 'EDIT RECORD',
    DOVE_MLS_CANCEL_AUTOGR: 'CANCELLED RECORDS',
    DOVE_MLS_SURVEYOR_AUTOGR: 'Surveyor Company',
    DOVE_AUTO_GR_EDIT: 'Confirmation to edit ',
    DOVE_GRADE_DATA: 'Inventory by Grade Data as on ',
    DOVE_VR_NO_NOM_FULL_RELET: 'No. of NOM Full Relet',
    DOVE_VR_NO_NOM_BACKLOADING: 'No. of NOM Backloading',
    DOVE_VR_TOTAL_YTD_SHIPMENTS: 'Total YTD Shipments',
    DOVE_VR_TOTAL_YTD_NOM_BACKLOADING_SHIPMENTS: 'Total YTD NOM Backloading Shipments',
    DOVE_VR_TOTAL_YTD_PARTIALRELET_SHIPMENTS: 'Total YTD Partial Relet Shipments',
    DOVE_VR_TOTAL_YTD_VALUE_REALISED: 'Total YTD Value Realised',
    DOVE_VR_TOTAL_MTD_NOM_BACKLOADING_SHIPMENTS: 'Total MTD NOM Backloading Shipments',
    DOVE_VR_TOTAL_MTD_PARTIAL_RELET_SHIPMENTS: 'Total MTD Partial Relet Shipments',
    DOVE_VR_TOTAL_MTD_SHIPMENTS: 'Total MTD Shipments',
    DOVE_VR_TOTAL_YTD_NOM_FULL_RELET_SHIPMENTS: 'Total YTD NOM Full Relet Shipments',
    DOVE_VR_TOTAL_YTD_FULL_RELET_SHIPMENTS: 'Total YTD Full Relet Shipments',
    DOVE_VR_TOTAL_MTD_VALUE_REALISED: 'Total MTD Value Realised',
    DOVE_VR_TOTAL_MTD_NOM_FULL_RELET_SHIPMENTS: 'Total MTD NOM Full Relet Shipments',
    DOVE_VR_TOTAL_MTD_FULL_RELET_SHIPMENTS: 'Total MTD Full Relet Shipments',
    DOVE_BACKLOADED_NO_SHIPMENTS: 'No. of relet shipments',
    DOVE_COUNTERPARTY: 'Counterparty',
    DOVE_CP_DATE: 'CP Date',
    DOVE_CP_COUNTERPARTY: 'CP Counterparty',
    DOVE_CPDATE_REQD: 'Please enter valid CP Date',
    DOVE_CPCOUNTERPARTY_REQD: 'Please enter a valid CP Counterparty',
    DOVE_MANUALLY_UPLOAD: 'Shipment from IMOS',
    DOVE_FILE_NAME: 'File Name',
    DOVE_LAST_UPDATED_BY: 'Last updated by',
    DOVE_MASTER_FILE_EDIT: 'Edit this file to update the depot master data',
    DOVE_MISSING_FILE: 'File is missing for',
    DOVE_EDIT_FILE: 'EDIT FILE',
    DOVE_MLS_OTHER_VESSEL_NAME: 'Other vessel name',
    DOVE_DEPOT_CODE: 'Depot Code',
    DOVE_DTP: 'DTP',
    DOVE_DTP_SIMULATOR_ENT_MARG: 'Enterprise Margin',
    DOVE_DTP_SIMULATOR_INC_DTP_PREMIUM: 'DTP Premium (-)',
    DOVE_DTP_SIMULATOR_INC_ENT_MARG: 'Incremental Enterprise Margin',
    DOVE_DTP_SIMULATOR_DTP_VALUE: 'DTP Value',
    DOVE_DTP_SIMULATOR_RMT_CTS: 'RMT CTS',
    DOVE_DTP_SIMULATOR_SIM_ENT_MARG: 'Enterprise Margin',
    DOVE_DTP_SIMULATOR_PDB_MARGIN: 'PDB Margin',
    DOVE_DTP_SIMULATOR_RMT_MARGIN: 'RMT Margin',
    DOVE_DTP_SIMULATOR_VAL_AT_RISK: 'Value at Risk (VAR)',
    DOVE_DTP_SIMULATOR_COST_OF_PROTECT: 'Cost of protecting VAR',
    DOVE_DTP_SIMULATOR_PETCO_TRADE_MARGIN: 'PETCO Trading Margin',
    DOVE_DTP_SIMULATOR_ALPHA_EXPO_PARITY: 'MTD Export Parity',
    DOVE_DTP_SIMULATOR_EXPO_PARITY: 'Average Export Parity',
    DOVE_DTP_SIMULATOR_FRIEGHT_NETBACK: 'Freight Netback',
    DOVE_DTP_SIMULATOR_ENT_MARG_BELOW: 'Enterprise Margin',
    DOVE_DTP_SIMULATOR_RMT_CTS_BELOW: 'RMT CTS',
    DOVE_DTP_TRACKING: 'DTP TRACKING - OVERVIEW',
    DOVE_DTP_REQUEST: 'DTP REQUEST',
    DOVE_DTP_TCVOLUME: 'Total Contract Volume ',
    DOVE_DTP_TCVALUE: 'Total Contract Value ',
    DOVE_DTP_TIEM: 'Total Incremental Enterprise Margin ',
    DOVE_TOTAL_CUSTOMERS: 'Total Customers',
    DOVE_DTP_SIMULATOR: 'DTP Simulator',
    DOVE_DTP_SIMULATOR_TITLE: 'DTP SIMULATOR',
    DTP_TRACKING_DETAIL: 'DTP TRACKING - DETAIL ',
    DTP_REQUEST_DETAILS: 'DTP REQUEST DETAILS',
    DTP_SUMMARY: 'DTP SUMMARY',
    DOVE_DTP_EO_APPROVER_SIMULATOR: 'View DTP Simulation',
    DOVE_DTP_EO_APPROVER_DETAILS: 'DTP Approval',
    DOVE_DTP_EO_SUBMIT: 'Approve Request',
    DOVE_DTP_EOA_SUBMIT: 'Submit for Approval',
    DOVE_DTP_EO_REJECT: 'Reject Request',
    kbbl: '(kbbl)',
    usd: '(usd)',
    usd_bbl: '(usd/bbl)',
    DOVE_REASON_FOR_DELAY: 'Please select a reason for delay',
    DOVE_PROCEED_TEXT: 'Proceed',
    DOVE_Not_applicable_tcVessel: 'Not applicable for TC vessels',
    DOVE_BUTTON_REASON_FOR_DELAY: 'Select reason for delay',
    DOVE_QUANTITY_BBL: 'Quantity (BBL)',
    DOVE_BLVOLUME_BBL: 'BL Volume (BBL)',
    DOVE_OPS: 'OPS',
    DOVE_LP_SURVEYOR: 'Loadport Surveyor',
    DOVE_DP_SURVEYOR: 'Disport Surveyor',
    DOVE_FORWARDING_AGENT: 'Forwarding Agent',
    DOVE_LOADING_AGENT: 'Loading Agent',
    DOVE_DISPORT_AGENT: 'Disport Agent',
    DOVE_RECAP_CPDATE: 'Recap CP Date',
    DOVE_ETA_NOTICE: 'ETA Notice',
    DOVE_ETA_LP: 'ETA Loadport',
    DOVE_ETB_LP: 'ETB Loadport',
    DOVE_WAITING_TIME_LP: 'Waiting Time Loadport',
    DOVE_ETA_DISPORT: 'ETA Disport',
    DOVE_ETB_DISPORT: 'ETB Disport',
    DOVE_WAITING_TIME_DP: 'Waiting Time Disport',
    DOVE_OVERALL_WAITING_TIME: 'Overall Waiting Time',
    DOVE_ALL_DEPOT: 'MLS - ALL DEPOTS',
    DOVE_REGENERATE_LATEST: 'Regenerate the Latest Data',
    DOVE_NO_DATA_RECORDED: 'No data recorded',
    DOVE_START_PLANNING_TEXT: 'Start Planning by loading the latest data.',
    DOVE_LOAD_LATEST_DATA: 'Load Latest Data',
    DOVE_PUBLISH_ASSIGNED: 'Publish the assigned shipment',
    DOVE_LDR_START: 'LDR Start',
    DOVE_LDR_END: 'LDR End',
    DOVE_DDR_START: 'DDR Start',
    DOVE_DDR_END: 'DDR End',
    DOVE_ASCENDING: 'Ascending',
    DOVE_DESENDING: 'Descending',
    DOVE_SORTBY: 'Sort by : ',
    DOVE_SHIPMENT_NO: 'Shipment No. : ',
    DOVE_SHIPMENT_NUM: 'Shipment No ',
    DOVE_UNASSIGNED_MESSAGE:
      'Please choose the shipment number from the dropdown list inside the Shipment No. column. Click Assign Shipment No. to assign the shipment.',
    DOVE_ASSIGN_SHIP_NUM: 'Assign Shipment No.',
    DOVE_SELECT: 'Select',
    DOVE_ShipmentNumberAssignValidation: 'Please change shipment number.',
    DOVE_DELETE_MESSAGE:
      'The shipment number will also be removed from the list. Are you sure you want to remove the shipment?',
    DOVE_RECAP_CP_DATE: 'Recap CP Date',
    DOVE_NORT_LOADPORT: 'NORT Loadport',
    DOVE_NORT_DISPORT: 'NORT Disport',
    DOVE_POB_LOADPORT: 'POB Loadport',
    DOVE_POB_DISPORT: 'POB Disport',
    DOVE_NEXT_LOADINGPORT: 'Next Loading Port',
    DOVE_ETA_NEXTLOADPORT: 'ETA Next Loadport',
    DOVE_MLS_ADD_SHIPMENT: 'Add New Shipment',
    DOVE_MLS_EXPAND: 'Expand',
    DOVE_MLS_COLLAPSE: 'Collapse',
    DOVE_LOADPORT_REQUIRED: 'Load Port Required',
    DOVE_VESSEL_REQUIRED: 'Vessel Name Required',
    DOVE_DISCHARGEPORT_REQUIRED: 'Discharge Port Required',
    DOVE_PRODUCT_REQUIRED: 'Product Required',
    DOVE_QUANTITYBBL_REQUIRED: 'Quantity (BBL) Required',
    DOVE_DISCHARGEPORT_REQUIRED: 'Discharge Port Required',
    DOVE_NORTLP_REQUIRED: 'NORT Loadport Required',
    DOVE_POBLP_REQUIRED: 'POB Loadport Required',
    DOVE_NORTDP_REQUIRED: 'NORT Disport Required',
    DOVE_POBDP_REQUIRED: 'POB Disport Required',
    DOVE_NEXTLP_REQUIRED: 'Next Loading Port Required',
    DOVE_OPS_REQUIRED: 'OPS Required',
    DOVE_LAGENT_REQUIRED: 'Loading Agent Required',
    DOVE_DAGENT_REQUIRED: 'Disport Agent Required',
    DOVE_LSURVEYOR_REQUIRED: 'Loadport Surveyor Required',
    DOVE_DSURVEYOR_REQUIRED: 'Disport Surveyor Required',
    DOVE_FAGENT_REQUIRED: 'Forwarding Agent Required',
    DOVE_DISCARD_CHANGES: 'Discard Changes',
    DOVE_UPDATE_CHANGES: 'Update Changes',
    DOVE_DISCARD_CONFIRM: 'Are you sure you want to discard the changes made ?',
    DOVE_DISCARD_POPUP: 'Are you sure you want to discard? All the changes will be lost.',
    DOVE_DISCARD_YES: 'Yes, Discard',
    DOVE_UPDATE_POPUP: 'Are you sure you want to update this changes?',
    DOVE_EDIT_YES: 'Yes, Edit',
    DOVE_ASSIGN_SHIPMENT: 'Assign Shipment',
    DOVE_PORTNO: 'Port No.',
    DOVE_ETALP_REQUIRED: 'ETA Loadport Required',
    DOVE_ETBLP_REQUIRED: 'ETB Loadport Required',
    DOVE_ETADP_REQUIRED: 'ETA Disport Required',
    DOVE_ETBDP_REQUIRED: 'ETB Disport Required',
    DOVE_MLS_ADD_NEW_PRODUCT: 'Add product',
    DOVE_BUTTON_ADD_NEW_VOYAGE: 'Add New Voyage',
    DOVE_LDR_REQUIRED: 'LDR Required',
    DOVE_Cancel_Voyage: 'Cancel voyage',
    DOVE_Cancel_Confirm: 'Are you sure you want to cancel this voyage? ',
  },
};
export default ENGLISH_TRANSLATION;
