import { put, call, take, race } from 'redux-saga/effects';
import { fetchAPI } from '@core/sagas/fetchAPI';
import { delay } from 'redux-saga';
import { httpMethod } from '@core/enums/httpMethod';
import { POLL_START } from '../actions/actionTypes';

/**
 * @description - Takes endpoint,calls API function & update the store.
 * @param {Object} payload  - Payload.
 * @returns {undefined}
 */
function* fetchDetails(payload) {
  while (true) {
    try {
      yield put({ type: payload.requestType, message: '' });
      // Params should be an array
      // If we need to handle multiple request we can make use of this
      const res = yield payload.params.map(p =>
        call(fetchAPI, payload.endPoint, p, httpMethod[payload.httpMethod]),
      );
      yield put(payload.successAction(res));
      yield call(delay, payload.pollDelay);
    } catch (e) {
      yield put(payload.errorAction(e.message));
      yield put({ type: payload.pollStopEvent, message: e.message });
    }
  }
}

/**
 * @description - Poll request watcher function.
 */
export default function* pollRequestWatcher() {
  while (true) {
    // Get the payload for the watch function
    const action = yield take(POLL_START);
    const payloadData = action.payload;
    yield race([call(fetchDetails, payloadData), take(payloadData.pollStopEvent)]);
  }
}
