import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './rounded_btn_normal_styles';

/**
 * @description - Describes the text component.
 * @param {Object} props - Text component properties.
 * @returns {string} - HTML templete of the text component.
 */
const RoundedBtnNormal = props => {
  const { children, classes, fontSize } = props;
  return (
    <span style={{ fontSize }} className={classes.fontstyle}>
      {children}
    </span>
  );
};

RoundedBtnNormal.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fontSize: PropTypes.string,
};
RoundedBtnNormal.defaultProps = {
  fontSize: '0.8125rem',
};

export default injectSheet(styles)(RoundedBtnNormal);
