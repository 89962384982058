import React from 'react';
import DynamicImport from '@core/components/DynamicImport';
import { getRole } from '@core/utils/configHelper';
import './routes.scss';

/**
 * @description - Loading function.
 * @param {string} k - Folder Name.
 * @returns {string} - Key folder name.
 */
const loadingFuntion = k => ({
  // k is actualy folder name which is very important
  // complete dynamic path is not supported in webpack for we are passing the key folder name
  // for more info look at Dynamic.js file
  key: `${k}`,
});

/**
 * @description - Dynamic import component for pdb.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for pdb.
 */
const Pdb = props => (
  <DynamicImport load={() => loadingFuntion('pdb')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for PricingAnalyst.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for PricingAnalyst.
 */
const PricingAnalyst = props => (
  <DynamicImport load={() => loadingFuntion('pricingAnalyst')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for PricingAnalyst.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for PricingAnalyst.
 */
const pricingApprover = props => (
  <DynamicImport load={() => loadingFuntion('pricingApprover')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for PricingAnalystPDB.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for PricingAnalyst.
 */
const PDBPricingAnalyst = props => (
  <DynamicImport load={() => loadingFuntion('pricingAnalystPDB')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for MelakaPlanner.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for MelakaPlanner.
 */
const MelakaPlanner = props => (
  <DynamicImport load={() => loadingFuntion('melakaPlanner')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for demandPlanner.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for demandPlanner.
 */
const demandPlanner = props => (
  <DynamicImport load={() => loadingFuntion('demandPlanner')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for demandPlanner.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for demandPlanner.
 */
const CBDdemandPlanner = props => (
  <DynamicImport load={() => loadingFuntion('CBDdemandPlanner')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for demandPlanner.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for demandPlanner.
 */
const RBDdemandPlanner = props => (
  <DynamicImport load={() => loadingFuntion('RBDdemandPlanner')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);
/**
 * @description - Dynamic import component for commercialPricingAnalyst.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for commercialPricingAnalyst.
 */
const commercialPricingAnalyst = props => (
  <DynamicImport load={() => loadingFuntion('commercialPricingAnalyst')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for enterpriseOptimizer.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for enterpriseOptimizer.
 */
const enterpriseOptimizer = props => (
  <DynamicImport load={() => loadingFuntion('enterpriseOptimizer')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for enterpriseOptimizer.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for enterpriseOptimizer.
 */
const petco = props => (
  <DynamicImport load={() => loadingFuntion('PETCO')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/** @description - Dynamic import component for admin.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for admin.
 */
const doveAdmin = props => (
  <DynamicImport load={() => loadingFuntion('kpi')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for petcoUser.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for petcoUser.
 */
const petcoUser = props => (
  <DynamicImport load={() => loadingFuntion('petcoUser')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

/**
 * @description - Dynamic import component for petcoUser.
 * @param {Object} props - Props.
 * @returns {Object} - Dynamic import component for petcoUser.
 */
const pdbInventoryFocal = props => (
  <DynamicImport load={() => loadingFuntion('pdbInventoryFocal')}>
    {Component => (Component === null ? <div className="skeleton" /> : <Component {...props} />)}
  </DynamicImport>
);

const routes = [
  {
    path: '/pdb',
    component: Pdb,
    role: getRole('/pdb'),
  },
  {
    path: '/pricing-analyst',
    component: PricingAnalyst,
    role: getRole('/pricing-analyst'),
  },
  {
    path: '/pdb-pricing-analyst',
    component: PDBPricingAnalyst,
    role: getRole('/pdb-pricing-analyst'),
  },
  {
    path: '/melaka-planner',
    component: MelakaPlanner,
    role: getRole('/melaka-planner'),
  },
  {
    path: '/demand-planner',
    component: demandPlanner,
    role: getRole('/demand-planner'),
  },
  {
    path: '/rbd-demand-planner',
    component: RBDdemandPlanner,
    role: getRole('/rbd-demand-planner'),
  },
  {
    path: '/cbd-demand-planner',
    component: CBDdemandPlanner,
    role: getRole('/cbd-demand-planner'),
  },
  {
    path: '/commercial-pricing-analyst',
    component: commercialPricingAnalyst,
    role: getRole('/commercial-pricing-analyst'),
  },
  {
    path: '/enterprise-optimizer',
    component: enterpriseOptimizer,
    role: getRole('/enterprise-optimizer'),
  },
  {
    path: '/petco',
    component: petco,
    role: getRole('/petco'),
  },
  {
    path: '/kpi',
    component: doveAdmin,
    role: getRole('/kpi'),
  },
  {
    path: '/petcoUser',
    component: petcoUser,
    role: getRole('/petcoUser'),
  },
  {
    path: '/pdbInventoryFocal',
    component: pdbInventoryFocal,
    role: getRole('/pdbInventoryFocal'),
  },
  {
    path: '/pricing-approver',
    component: pricingApprover,
    role: getRole('/pricing-approver'),
  },
];

export default routes;
