/*
        Name : Generic Loading Component
*/
import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import styles from './loading_style';

/**
 * @description - Generic Loading Component.
 */
class Loading extends React.PureComponent {
  /**
   * @description - Function to render loading component.
   */
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loadingContainer}>
        <div className={classNames(classes.dot, classes.dot1)} />
        <div className={classNames(classes.dot, classes.dot2)} />
        <div className={classNames(classes.dot, classes.dot3)} />
      </div>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default injectSheet(styles)(Loading);
