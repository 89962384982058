/*
        Name : ActionTypeList v1.0
        Purpose: defines all action types in the application
*/
export const ADD_HEADER = 'ADD_HEADER';
export const REMOVE_HEADER = 'REMOVE_HEADER';
export const SAVE_ROUTENAME = 'SAVE_ROUTENAME';
export const ADD_ROUTES = 'ADD_ROUTES';
export const GET_ROLE = 'GET_ROLE';
export const SAVE_ROLE = 'SAVE_ROLE';
export const PRODUCT_DETAILS = 'PRODUCT_DETAILS';
export const SAVE_PRODUCT_DETAILS = 'SAVE_PRODUCT_DETAILS';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SHOW_TOAST_MSG = 'SHOW_TOAST_MSG';
export const CLOSE_TOAST_MSG = 'CLOSE_TOAST_MSG';
export const TOAST_MSG_FAILURE = 'TOAST_MSG_FAILURE';
export const POLL_START = 'POLL_START';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN';
export const GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE';
export const LOCALE_SELECTED = 'LOCALE_SELECTED';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const GET_USER_CLAIM = 'GET_USER_CLAIM';
export const LOGOUT_TOKEN = 'LOGOUT_TOKEN';
export const LOGOUT_TOKEN_SUCCESS = 'LOGOUT_TOKEN_SUCCESS';
export const DOWNLOAD_FILES = 'DOWNLOAD_FILES';
export const TOKEN_NOT_AVAILABLE = 'TOKEN_NOT_AVAILABLE';
export const GET_USER_CLAIM_REQUEST = 'GET_USER_CLAIM_REQUEST';
export const GET_USER_CLAIM_SUCCESS = 'GET_USER_CLAIM_SUCCESS';
export const GET_USER_CLAIM_FAILED = 'GET_USER_CLAIM_FAILED';
export const TOASTER_FAILED = 'TOASTER_FAILED';
export const SAVE_ROUTE_PARAMS = 'SAVE_ROUTE_PARAMS';
export const CLEAR_ROUTE_PARAMS = 'CLEAR_ROUTE_PARAMS';
export const LOGOUT = {
  GET: 'LOGOUT_TOKEN',
  SUCCESS: 'LOGOUT_TOKEN_SUCCESS',
  FAIL: 'LOGOUT_TOKEN_FAILURE',
};
export const MULTISELECT = {
  UPDATE: 'SELECT_UPDATE',
  CLEAR: 'SELECT_CLEAR',
};
export const PERSONA = {
  UPDATE: 'PERSONA_UPDATE',
  CLEAR: 'PERSONA_CLEAR',
};
export const FILE_BULK_UPLOAD = {
  POST: 'FILE_BULK_UPLOAD_POST',
  SUCCESS: 'FILE_BULK_UPLOAD_SUCCESS',
  FAIL: 'FILE_BULK_UPLOAD_FAILED',
  CLEAR: 'FILE_BULK_UPLOAD_CLEAR',
};

export const UPLOAD_PROGRESS = {
  SUCCESS: 'UPLOAD_PROGRESS_SUCCESS',
  CLEAR: 'UPLOAD_PROGRESS_CLEAR',
};
