import mixins from '@styles/common/mixins';
import colors from '@styles/common/color_variables.scss';

const styles = {
  detailsContaier: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px',
    width: '50%',
  },
  label: {
    fontWeight: '700',
  },
  versionContainer: {},
  dateContainer: {},
  browserContainer: {},
  mainContainer: {
    display: 'flex',
    backgroundColor: colors.card_background_color,
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },
  copyRight: {
    ...mixins.pxtorem(14),
  },
  logoContaier: {
    paddingTop: '50px',
  },
};

export default styles;
