import doveBG from '@assets/images/landing/dove_bg.svg';
import colors from '@styles/common/color_variables.scss';

export const styles = {
  landingPage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `linear-gradient(-179deg, ${colors.landing_bg_gradient_variant_1} 40%,
      ${colors.landing_bg_gradient_variant_2} 100%)`,
  },
  landingContainer: {
    width: '100%',
    height: '81%',
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'repeat',
    backgroundImage: `url(${doveBG})`,
    animation: 'slide 80s linear infinite',
  },
  doveText: {
    width: '136px',
    margin: '10px 0',
    alignSelf: 'center',
  },
  doveLogo: {
    width: '46px',
    margin: '10px 0 10px 10px',
    alignSelf: 'center',
  },
  btnContainer: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  message: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  loader: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  '@keyframes slide': {
    from: { backgroundPosition: '0 0' },
    to: { backgroundPosition: '-100vw 0' },
  },
};

export { styles as default };
