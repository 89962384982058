const role = {
  'db95dc73-a7d6-4d28-894f-f6a3d158d473': '/pdb',
  'b76db15c-8ac9-4613-9ce3-fe80fa53358d': '/demand-planner',
  'bf86ed9f-e30d-4996-9252-6a55b1735483': '/pdb-pricing-analyst',
  '4df5dbc5-f220-48b2-b96e-d83fb0b30caf': '/pricing-analyst',
  '085d05f6-48cf-4b83-b51c-3f060f937d76': '/melaka-planner',
  'd4e64c74-b520-4688-81a2-4f4c6c77b521': '/commercial-pricing-analyst',
  '3c3b35f8-d9b1-4789-bd9f-42dcef323ef3': '/enterprise-optimizer',
  '6f7dd3ff-9398-43fd-8e3b-4cbe40e4465e': '/rbd-demand-planner',
  'd6ec7073-0403-4720-9fd8-41f1b96a0917': '/cbd-demand-planner',
  '4524873d-a7ac-4e7e-ae07-b50887346baa': '/petco',
  'ef810f5c-e418-468f-8270-6c21b02f5704': '/kpi',
  '4fdfd6fe-d23a-495b-bea0-c1c56c2837f9': '/petcoUser',
  'ddc3d729-60aa-4f13-8d4c-73bb41baed4c': '/pdbInventoryFocal',
  'a456bdd1-aa51-442f-8449-229100e7b09b': '/pricing-approver',
};
export default role;
