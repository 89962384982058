import { isValidEnv } from './featureFlagEnv';
import pdsConfig from './personaConfigurations/pds';
import eoConfig from './personaConfigurations/retailpricingEO';
import melakaConfig from './personaConfigurations/melakaPlanner';
import petcoConfig from './personaConfigurations/petco';
import cpaConfig from './personaConfigurations/cmmrcialPricingAnlyst';
import petcoUserConfig from './personaConfigurations/petcoUser';

// Flags for enabling/disabling a feature
const Featureflag = {
  MULTIPERSONA: true,
  AZUREADFSLOGOUT: true,
  MDF: true,
  MPL: true,
  AZUREOPENID: true,
  CTSEOREGION: isValidEnv(eoConfig.pricing.regionFilterFix),
  disableUpcoming: isValidEnv(melakaConfig.berthOccupancy.disableUpcoming),
  CBDBIBTN: isValidEnv(cpaConfig.cbdTable.runPowerBIButton),
  CTSEOHISTORY: isValidEnv(eoConfig.pricing.dailyHistoryLoad),
  INVDOWNLOAD: isValidEnv(petcoConfig.inventoryRefinery.download),
  OSVTLTIP: isValidEnv(pdsConfig.dashboard.osvtltip),
  BOOKINV: isValidEnv(petcoConfig.inventoryDepot.bookInv),
  MDFSUMMARYDATE: isValidEnv(petcoConfig.inventoryDepot.mdfSummaryDate),
  TRENDDEPOT: isValidEnv(petcoConfig.inventoryTrend.trendDepot),
  FIFO: isValidEnv(petcoConfig.petcoInventory.fifo),
  BUGFIXES: isValidEnv(petcoConfig.petcoInventory.bugFixes),
  REFINERYLIFTINGTRACKER: isValidEnv(pdsConfig.dashboard.refineryLiftingTracker),
  OPERATIONALALERTS: isValidEnv(pdsConfig.dashboard.operationalalerts),
  INCLUDESTOCKDAYS: isValidEnv(petcoConfig.petcoInventory.includeStockdays),
  ADDINGBLDATE: isValidEnv(petcoConfig.petcoInventory.addingBlDate),
  PRODUCTTAB: isValidEnv(petcoConfig.petcoInventory.productTab),
  REPLENISHMENTDATEVOLUME: isValidEnv(petcoConfig.petcoInventory.replenishment),
  BUNKERVLF: isValidEnv(pdsConfig.dashboard.bunkervlf),
  AUTOGR: isValidEnv(pdsConfig.dashboard.autogr),
  VRMTBE: isValidEnv(petcoUserConfig.vrDashboard.mtbeEnhance),
  OVERALLLIFTING: isValidEnv(petcoConfig.inventoryLifting.liftingOverall),
  DEVIATIONLIFTING: isValidEnv(petcoConfig.inventoryLifting.liftingDeviation),
  PLIUI: isValidEnv(pdsConfig.dashboard.pliui),
  VESSELTRACKER: isValidEnv(pdsConfig.dashboard.vesseltracker),
  THIRDPARTY: isValidEnv(pdsConfig.dashboard.thirdparty),
  THIRDPARTYVR: isValidEnv(petcoUserConfig.vrDashboard.thirdPartyVR),
  DYNAMICFILE: isValidEnv(petcoConfig.inventoryFile.dynamicFile),
  PRICEBENCHMARK: isValidEnv(petcoConfig.inventoryTrend.priceBenchmark),
  CPADTPMVP2: isValidEnv(cpaConfig.cpadtp.mvp2),
  DTPEO: isValidEnv(eoConfig.pricing.dtpEo),
  ALLOPTION: isValidEnv(petcoConfig.generalInventory.allOption),
  SALESTREND: isValidEnv(pdsConfig.dashboard.salesTrend),
  PSRSEGREGATION: isValidEnv(petcoConfig.inventoryTrend.psrSegregation),
  MLSMANUAL: isValidEnv(pdsConfig.dashboard.mlsmanual),
  BASEMLS: isValidEnv(pdsConfig.dashboard.mlsAutomation),
  MLSEDITING: isValidEnv(pdsConfig.dashboard.mlsEditing),
  MLSRUNNINGNO: isValidEnv(pdsConfig.dashboard.mlsRunningNo),
  MLSCOMMENTBOX: isValidEnv(pdsConfig.dashboard.mlscommentbox),
  CPADTPEDITING: isValidEnv(cpaConfig.cpadtp.dtpEditing),
  PHYINVHIDE: isValidEnv(petcoConfig.inventoryDepot.hideLbnSbft),
};
export default Featureflag;
