import '@styles/museosans.scss';
import '@styles/opensans.scss';

const mixins = {
  /**
   * @description - Function to define MuseoSans ExtraBold font.
   * @returns {Object} - Returns the font object.
   */
  MuseoSans900() {
    return {
      'font-family': 'MuseoSans-900',
      'font-weight': 900,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define MuseoSansBold font.
   * @returns {Object} - Returns the font object.
   */
  MuseoSans700() {
    return {
      'font-family': 'MuseoSans-700',
      'font-weight': 700,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define MuseoSans semi Bold font.
   * @returns {Object} - Returns the font object.
   */
  MuseoSans300() {
    return {
      'font-family': 'MuseoSans-300',
      'font-weight': 300,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansRegular font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansRegular() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 400,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSans SemiBold font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansSemibold() {
    return {
      'font-family': 'Open Sans',
      'font-weight': 600,
      'font-style': 'normal',
    };
  },

  /**
   * @description - Function to define OpenSansBold font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansBold() {
    return {
      'font-family': 'Open Sans',
      'font-style': 'normal',
      'font-weight': 700,
    };
  },

  /**
   * @description - Function to define OpenSansItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansItalic() {
    return {
      'font-family': 'Open Sans Italic',
    };
  },

  /**
   * @description - Function to define OpenSansItalic font.
   * @returns {Object} - Returns the font object.
   */
  OpenSansItalicBold() {
    return {
      'font-family': 'Open Sans',
      'font-style': 'italic',
      'font-weight': 700,
    };
  },
};

export default mixins;
