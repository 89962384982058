/**
 * @description - Flag value mapper
 * @param {*} v1 - V1 - uat flag.
 * @param {*} v2 - V2 prod flag.
 * @param {*} v3 - V3 local flag.
 * @param {*} v4 - V4 qaint flag.
 * @param {*} v5 - V5 dev flag.
 * @returns {Object} JSON node.
 */
export default function valueMap(v1, v2, v3, v4, v5) {
  return {
    uat: v1,
    prod: v2,
    local: v3,
    primeqaint: v4,
    primedev: v5,
  };
}
