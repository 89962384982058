import valueMap from '../flagValueMapper';
/**
 * @description - Flag value mapper
 * @param {*} v1 - V1 - uat flag.
 * @param {*} v2 - V2 prod flag.
 * @param {*} v3 - V3 local flag.
 * @param {*} v4 - V4 qaint flag.
 * @param {*} v5 - V5 dev flag.
 * @returns {Object} JSON node.
 */

const config = {
  generalInventory: {
    allOption: valueMap(true, true, true, true, false),
  },
  inventoryTrend: {
    trendDepot: valueMap(true, true, true, true, false),
    priceBenchmark: valueMap(true, true, true, true, false),
    psrSegregation: valueMap(false, false, false, true, false),
  },
  inventoryRefinery: {
    download: valueMap(false, false, true, true, false),
  },
  inventoryDepot: {
    bookInv: valueMap(false, false, true, true, false),
    mdfSummaryDate: valueMap(false, false, true, true, false),
    hideLbnSbft: valueMap(false, false, false, false, false),
  },
  petcoInventory: {
    fifo: valueMap(false, false, true, true, false),
    bugFixes: valueMap(false, false, true, true, false),
    includeStockdays: valueMap(true, true, true, true, false),
    productTab: valueMap(true, true, true, true, false),
    addingBlDate: valueMap(false, false, true, true, false),
    replenishment: valueMap(false, false, true, true, false),
  },
  inventoryLifting: {
    liftingOverall: valueMap(false, false, true, true, false),
    liftingDeviation: valueMap(false, false, true, true, false),
  },
  inventoryFile: {
    dynamicFile: valueMap(false, false, true, true, false),
  },
};
export default config;
