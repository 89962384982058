import { PERSONA } from '@core/actions/actionTypes';

const initialState = {
  userPersona: '',
};
/**
 * @description - User reducer.
 * @param {Object} state - State.
 * @param {Object} action - Action.
 * @returns {Object} - Updated state.
 */
const personaReducer = (state = initialState, action) => {
  switch (action.type) {
    case PERSONA.UPDATE:
      return { userPersona: action.payload };

    case PERSONA.CLEAR:
      return Object.assign({}, state, {
        userPersona: '',
      });
    default:
      return state;
  }
};
export default personaReducer;
