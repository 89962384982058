/* eslint-disable no-nested-ternary */
import React from 'react';
import ReactDOM from 'react-dom';
import '@styles/index.scss';
import 'core-js';
// import 'core-js/es6/promise';
// import 'core-js/fn/object/assign';
// import 'core-js/fn/array/find-index';
// import 'core-js/fn/array/includes';
// import 'core-js/fn/array/find';
// import 'core-js/fn/object/values';
// import 'core-js/fn/symbol';
import 'airbnb-browser-shims';

import { BrowserRouter } from 'react-router-dom';
import { createInjectSagasStore, sagaMiddleware } from 'redux-sagas-injector';
import { applyMiddleware, compose } from 'redux';
import throttle from 'lodash.throttle';
import interceptor from '@core/base/interceptor';

import { Provider } from 'react-redux';
/* Localization */
import { loadState, saveState } from '@core/base/stateStorage';
import TagManager from 'react-gtm-module';
import { init as initApm } from '@elastic/apm-rum';
import rootSaga from './core/sagas';
import * as serviceWorker from './serviceWorker';
import App from './App';
import coreReducer from './core/reducer';
// import { runGTM } from './core/utils/gtmService';

const tagManagerArgs = {
  gtmId: 'GTM-N85FXQ7',
};

const { host } = window.location;

TagManager.initialize(tagManagerArgs);

// runGTM();
const peristedState = loadState();
const composeEnhancers =
  typeof window === 'object' && window.REDUX_DEVTOOLS_EXTENSION_COMPOSE
    ? window.REDUX_DEVTOOLS_EXTENSION_COMPOSE({})
    : compose;
const enhancers = [
  applyMiddleware(
    sagaMiddleware,
    // routerMiddleware(hashHistory),
  ),
  composeEnhancers,
];
// const initialState = {};
const store = createInjectSagasStore(
  { rootSaga },
  coreReducer,
  peristedState,
  compose(...enhancers),
);
// saving state on store change
store.subscribe(
  throttle(() => {
    saveState({
      pdbReducer: {
        getDIReducer: store.getState().pdbReducer.getDIReducer,
        getVIReducer: store.getState().pdbReducer.getVIReducer,
      },
    });
  }, 1000),
);
// register axios interceptor
interceptor.setupInterceptors(store);

initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  // Service name is up to you but please following naming convention if you have many FE
  serviceName: 'dove-ui',

  // My Elasticsearch server
  serverUrl: 'https://prd.apm.petronas.com:443',

  // Using one of these value depend on your environment
  environment: host.includes('primeqaint')
    ? 'Development'
    : host.includes('uat')
    ? 'Staging'
    : 'Production',

  // If your backend and frontend host on different domain names
  distributedTracingOrigins: host.includes('primeqaint')
    ? ['https://api.primeqaint.dove.petronas.com']
    : host.includes('uat')
    ? ['https://api.uat.dove.petronas.com']
    : ['https://api.dove.petronas.com'],
  propagateTracestate: true,
});
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
export default store;
