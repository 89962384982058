export const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  HEAD: 'HEAD',
  DOWNLOAD: 'DOWNLOAD',
  POSTUPDATE: 'POSTUPDATE',
  GETUPDATE: 'GETUPDATE',
  FORMPOST: 'FORMPOST',
  FILEUPLOAD: 'FILEUPLOAD',
};

export { httpMethod as default };
