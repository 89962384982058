/*
        Name : Actions v1.0
        Purpose: defines all actions
*/
// eslint-disable-next-line dove-internal/no-global-action-type
import * as actionType from './actionTypes';

/**
 * @description - Add Header action.
 * @returns {Object} - Actions type with payload.
 */
export const addHeader = () => ({
  type: actionType.ADD_HEADER,
  payload: true,
});

/**
 * @description - Remove Header action.
 * @returns {Object} - Actions type with payload false.
 */
export const removeHeader = () => ({
  type: actionType.REMOVE_HEADER,
  payload: false,
});

/**
 * @description - Save route name action.
 * @param {Object} routeName - Route name object.
 * @returns {Object} - Actions type with payload.
 */
export const saveRouteName = routeName => ({
  type: actionType.SAVE_ROUTENAME,
  payload: routeName,
});

/**
 * @description - Gets selected locale.
 * @param {Object} locale - Locale object.
 * @returns {Object} - Actions type with locale.
 */
export function selectedLocale(locale) {
  return {
    type: actionType.LOCALE_SELECTED,
    locale,
  };
}

/**
 * @description - Gets product details.
 * @returns {Object} - Actions type.
 */
export function productDetails() {
  return {
    type: actionType.PRODUCT_DETAILS,
  };
}

/**
 * @description - Stores product details.
 * @param {Object} data - Payload data object.
 * @returns {Object} - Actions type with payload.
 */
export function storeProductDetails(data) {
  return {
    type: actionType.SAVE_PRODUCT_DETAILS,
    payload: data,
  };
}

/**
 * @description - Show toast action.
 * @param {Object} data - Payload data object.
 * @returns {Object} - Actions type with payload.
 */
export function ShowToast(data) {
  return {
    type: actionType.SHOW_TOAST,
    payload: data,
  };
}

/**
 * @description - Show Toast message.
 * @param {Object} data - Toast object.
 * @returns {Object} - Action with payload.
 */
export function ShowToastMsg(data) {
  return {
    type: actionType.SHOW_TOAST_MSG,
    payload: data,
  };
}

/**
 * @description Close Toast message.
 * @returns {Object} - Action.
 */
export function closeToaster() {
  return {
    type: actionType.CLOSE_TOAST_MSG,
  };
}

/**
 * @description - Failure Toast.
 * @param {Object} data - Toast object.
 * @returns {Object} - Action with payload.
 */
export function failureToast(data) {
  return {
    type: actionType.TOAST_MSG_FAILURE,
    payload: data,
  };
}

/**
 * @description - Start Poll.
 * @param {Object} data - Toast object.
 * @returns {Object} - Action with payload.
 */
export function startPoll(data) {
  return {
    type: actionType.POLL_START,
    payload: data,
  };
}

/**
 * @description - Gets Token value.
 * @param {string} urlParameter - Url parameters.
 * @returns {Object} - Action with payload.
 */
export function getToken(urlParameter) {
  return {
    type: actionType.GET_TOKEN,
    payload: urlParameter,
  };
}

/**
 * @description - Gets Refresh Token.
 * @param {string} urlParameter - Url parameters.
 * @returns {Object} - Action with payload.
 */
export function getRefreshToken(urlParameter) {
  return {
    type: actionType.GET_REFRESH_TOKEN,
    payload: urlParameter,
  };
}

/**
 * @description - Save Token value.
 * @param {Object} tokenObj - Token data object.
 * @returns {Object} - Action with payload.
 */
export function saveToken(tokenObj) {
  return {
    type: actionType.SAVE_TOKEN,
    payload: tokenObj,
  };
}

/**
 * @description - Get User data.
 * @returns {Object} - Action.
 */
export function getUser() {
  return {
    type: actionType.GET_USER_CLAIM,
  };
}

/**
 * @description - Logout Token.
 * @param {boolean} isTokenValid - Token is valid or invalid.
 * @returns {Object} - Action with payload.
 */
export function logoutToken(isTokenValid) {
  return {
    type: actionType.LOGOUT.GET,
    payload: isTokenValid,
  };
}

/**
 * @description - Logout success.
 * @returns {Object} - Action with payload.
 */
export function logoutSuccess() {
  return {
    type: actionType.LOGOUT.SUCCESS,
    payload: null,
  };
}

/**
 * @description - Download Files.
 * @param {Object} data - Token data.
 * @returns {Object} - Action with payload.
 */
export function downloadFiles(data) {
  return {
    type: actionType.DOWNLOAD_FILES,
    payload: data,
  };
}

/**
 * @description Stores selected mls filter options.
 * @param {Object} data - Filter options.
 * @returns {Object} - Action.
 */
export function dropdownSelctionUpdated(data) {
  return {
    type: actionType.MULTISELECT.UPDATE,
    payload: data,
  };
}

/**
 * @description Stores selected mls filter options.
 * @returns {*} - Action.
 */
export function cleardropdownSelction() {
  return {
    type: actionType.MULTISELECT.CLEAR,
  };
}

/**
 * @description Stores selected mls filter options.
 * @param {Object} data - Filter options.
 * @returns {Object} - Action.
 */
export function perosnaDetailsUpdate(data) {
  return {
    type: actionType.PERSONA.UPDATE,
    payload: data,
  };
}

/**
 * @description - Function to upload file.
 * @param {Object} data - Payload data.
 * @returns {Object} - Returns type and payload.
 */
export function bulkUploadFile(data) {
  return {
    type: actionType.FILE_BULK_UPLOAD.POST,
    payload: data,
  };
}

/**
 * @description - Function to save uploaded info.
 * @param {Object} data - API data.
 * @returns {Object} - Returns type and payload.
 */
export function setFileUploadError(data) {
  return {
    type: actionType.FILE_BULK_UPLOAD.SUCCESS,
    payload: data,
  };
}

/**
 * @description - Function to save upload progress percentage.
 * @param {Object} data - API data.
 * @returns {Object} - Returns type and payload.
 */
export function saveUploadProgress(data) {
  return {
    type: actionType.UPLOAD_PROGRESS.SUCCESS,
    payload: data,
  };
}

/**
 * @description - Function to clear file upload progress.
 * @returns {Object} - Action type object.
 */
export function clearUploadProgress() {
  return {
    type: actionType.UPLOAD_PROGRESS.CLEAR,
  };
}
