/* eslint-disable max-lines-per-function */
import { takeEvery, put, call } from 'redux-saga/effects';
import fetchAPI from '@core/sagas/fetchAPI';
import { httpMethod } from '@core/enums/httpMethod';
import {
  GET_USER_CLAIM,
  GET_USER_CLAIM_REQUEST,
  GET_USER_CLAIM_SUCCESS,
  GET_USER_CLAIM_FAILED,
} from '@core/actions/actionTypes';
import FeatureFlag from '@core/utils/featureFlag';
import getUserData from '../factory/userDetailsFactory';

/**
 * @description - Fetch details.
 */
function* fetchDetails() {
  const isMultipersona = FeatureFlag.MULTIPERSONA;
  const endPoint = isMultipersona ? 'getusermultipersona' : 'getUserClaim';
  try {
    yield put({ type: GET_USER_CLAIM_REQUEST, message: '' });
    const res = yield call(fetchAPI, endPoint, {}, httpMethod.GET);
    const resData = getUserData(res.data);
    yield put({ type: GET_USER_CLAIM_SUCCESS, payload: resData });
  } catch (e) {
    yield put({ type: GET_USER_CLAIM_FAILED, payload: e.message });
  }
}

/**
 * @description - Takes endpoint,calls API function & update the store.
 */
export default function* watchUserClaims() {
  yield takeEvery(GET_USER_CLAIM, fetchDetails);
}
