/*
        Name : CommunicationService v1.0
        Purpose: communication layer for creating service requests
        Created Date: 5th Oct 2018
*/
import axios from 'axios';
import { getApiDomain } from '@core/utils/configHelper';
import FeatureFlag from '@core/utils/featureFlag';
import { saveUploadProgress } from '@core/actions';
import config from './config';
import store from '../../index';

/**
 * @description - Gets access token from session storage.
 * @returns {string} - Returns accesstoken.
 */
function getToken() {
  return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
}

/**
 * @description - Gets Header for API call.
 * @param {string} contentType - The type of data that will be fetched.
 * @param {string} token - Authorisation token.
 */
function getHeader(contentType, token) {
  const headers = {
    'Content-Type': contentType,
    Authorization: token,
  };
  if (!FeatureFlag.AZUREOPENID) {
    headers['Active-Persona'] = sessionStorage.getItem('selectedUserRole')
      ? sessionStorage.getItem('selectedUserRole')
      : '';
  }
  return { headers };
}

/**
 * @description - Function to get base url from config.
 * @returns {string} - Base url.
 */
function getBaseServiceUrl() {
  const MOCK_ENVIORNMENT = 'true';
  if (process.env.REACT_APP_MOCK_ENV === MOCK_ENVIORNMENT) {
    return 'http://localhost:3005/';
  }
  return getApiDomain();
}

/**
 * @description - Function to stringify json.
 * @param {Object} data - Data.
 * @returns {Object} - Stringified data.
 */
function getstringifiedData(data) {
  return JSON.stringify(data);
}

/**
 * @description - All backend communications are handled here.
 */
export default class Communication {
  /**
   * @description - Constructor to initialize values.
   */
  constructor() {
    this.options = {};
    this.url = '';
  }

  /**
   * @description - Function to retrieve data from server.
   * @param {string} endpoint - Endpoint.
   * @param {Object} options - Options.
   * @returns {Promise} - Data obtained from service.
   */
  static getData(endpoint, options = {}) {
    this.options = { ...getHeader('application/json', getToken()), ...options };
    this.url = getBaseServiceUrl() + config.endpoints[endpoint.toLowerCase()];
    return axios.get(this.url, this.options);
  }

  /**
   * @description - Function to add data to server
   * @param {string} endpoint - Endpoint.
   * @param {Object} data - Data.
   * @param {Object} options - Options.
   * @returns {Promise} - Data (receipt) obtained from sever when data has been successfully created.
   */
  static postData(endpoint, data = {}, options = {}) {
    this.options = { ...options, ...getHeader('application/json', getToken()) };
    this.url = getBaseServiceUrl() + config.endpoints[endpoint.toLowerCase()];
    const postData = getstringifiedData(data);
    return axios.post(this.url, postData, this.options);
  }

  /**
   * @description - Function to add data to server
   * @param {string} endpoint - Endpoint.
   * @param {Object} data - Data.
   * @param {Object} options - Options.
   * @returns {Promise} - Data (receipt) obtained from sever when data has been successfully created.
   */
  static postFileData(endpoint, data = {}, options = {}) {
    this.url = getBaseServiceUrl() + config.endpoints[endpoint.toLowerCase()];
    this.options = {
      ...options,
      ...getHeader('multipart/form-data', getToken()),
    };
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.file.name);
    return axios.post(this.url, formData, this.options);
  }

  /**
   * @description - Function to add data to server
   * @param {string} endpoint - Endpoint.
   * @param {Object} data - Data.
   * @param {Object} options - Options.
   * @returns {Promise} - Data (receipt) obtained from sever when data has been successfully created.
   */
  static postMultiFileData(endpoint, data = {}, options = {}) {
    this.url = getBaseServiceUrl() + config.endpoints[endpoint.toLowerCase()];
    this.options = {
      ...options,
      ...getHeader('multipart/form-data', getToken()),
    };
    const formData = new FormData();

    data.files.forEach(file => {
      formData.append('file', file);
    });
    return axios.post(this.url, formData, this.options);
  }

  /**
   * @description - Function to Update data to server
   * @param {string} endpoint - Endpoint.
   * @param {Object} data - Data.
   * @param {Object} options - Options.
   * @returns {Promise} - Data (receipt) obtained from sever when data has been successfully updated.
   */
  static putData(endpoint, data = {}, options = {}) {
    this.options = { ...options, ...getHeader('application/json', getToken()) };
    this.url = getBaseServiceUrl() + config.endpoints[endpoint.toLowerCase()];
    const postData = getstringifiedData(data);
    return axios.put(this.url, postData, this.options);
  }

  /**
   * @description - Function to post files to server.
   * @param {Object} formData - Form Data.
   * @param {string} endpoint - Endpoint.
   * @returns {Promise} - Data (receipt) obtained from sever when dat has been successfully uploaded.
   */
  static postFormData(formData, endpoint) {
    // 'Content-Type': 'multipart/form-data'
    this.options = getHeader('application/x-www-form-urlencoded; charset=UTF-8', getToken());
    this.options.onUploadProgress = progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      store.dispatch(saveUploadProgress(percentCompleted));
      return percentCompleted;
    };
    this.url = getBaseServiceUrl() + config.endpoints[endpoint];
    // Form Data has to be generated from raw Data in a function from bussiness logic file
    return axios.post(this.url, formData, this.options);
  }
}
