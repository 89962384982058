import { takeEvery, call, put } from 'redux-saga/effects';
import { saveToken, getRefreshToken } from '@core/actions';
import queryString from 'query-string';
import { GET_TOKEN, TOKEN_NOT_AVAILABLE } from '../actions/actionTypes';

/**
 * @description - Validate toke Object.
 * @param {Object} tokenObj - Token Object.
 * @returns {boolean} - True if access token and refresh token property exist.
 */
function validateTokenObj(tokenObj) {
  return (
    Object.hasOwnProperty.call(tokenObj, 'accessToken') &&
    Object.hasOwnProperty.call(tokenObj, 'refreshToken')
  );
}

/**
 * @description - Get base 64 Token.
 * @param {Object} param - Param.
 * @returns {Object} - Base 64 token.
 */
function getBase64Token(param) {
  return JSON.parse(window.atob(param.token));
}

/**
 * @description - Get session token.
 * @returns {Object} - Returns tokenStatus, accessToken, refreshToken.
 */
function getSessionToken() {
  return {
    tokenStatus: 1,
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  };
}

/**
 * @description - Validate toke Object.
 * @param {Object} param - Param.
 * @returns {boolean} - True if access token  property exist.
 */
function validateUrlParameter(param) {
  return param && Object.hasOwnProperty.call(param, 'token');
}

/**
 * @description - Check if Token Available In Session.
 * @returns {boolean} - True if access token and refreshToken property exist.
 */
function isTokenAvailableInSession() {
  return localStorage.getItem('accessToken') && localStorage.getItem('refreshToken');
}

/**
 * @description - Validate token.
 * @param {Object} urlParam - Url param.
 * @returns {boolean} - True or false.
 */
function validateToken(urlParam) {
  const param = urlParam;
  if (!validateUrlParameter(param)) return false;
  const tokenObj = getBase64Token(param);
  if (!validateTokenObj(tokenObj)) return false;
  return true;
}

/**
 * @description - Saving token if already available in session storage else calling refresh token.
 * @param {Object} urlParam - Url param.
 */
function* loadToken(urlParam) {
  if (isTokenAvailableInSession()) {
    yield put(saveToken(getSessionToken()));
  } else {
    yield put(getRefreshToken(getBase64Token(urlParam)));
  }
}

/**
 * @description - Read token.
 * @param {Object} param - Url param.
 */
function* readToken(param) {
  const urlParam = queryString.parse(param);
  if (validateToken(urlParam)) {
    yield call(loadToken, urlParam);
  } else {
    yield put({ type: TOKEN_NOT_AVAILABLE });
  }
}

/**
 * @description - Get token.
 * @param {Object} action - Action.
 */
function* getToken(action) {
  yield call(readToken, action.payload);
}

/**
 * @description - Watches for the auth token.
 */
export default function* watchAuthToken() {
  yield takeEvery(GET_TOKEN, getToken);
}
