/* eslint-disable complexity */
import Communication from '@core/base/communication';
import { httpMethod } from '@core/enums/httpMethod';

/**
 * @description - Get request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} options - Options.
 */
function getRequest(endPoint, options = {}) {
  return Communication.getData(endPoint, options)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Post request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} options - Options.
 */
function postRequest(endPoint, param = {}, options) {
  return Communication.postData(endPoint, param, options)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Post request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} options - Options.
 */
function postFileRequest(endPoint, param = {}, options) {
  return Communication.postFileData(endPoint, param, options)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Post request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} options - Options.
 */
function postMultiFileRequest(endPoint, param = {}, options) {
  return Communication.postMultiFileData(endPoint, param, options)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Post request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} options - Options.
 */
function putRequest(endPoint, param = {}, options) {
  return Communication.putData(endPoint, param, options)
    .then(response => response.data)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Download request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} options - Options.
 */
function downloadRequest(endPoint, param = {}, options = {}) {
  const fileOptions = { ...options, responseType: 'blob' };
  return Communication.postData(endPoint, param, fileOptions)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Post request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} options - Options.
 */
function postUpdateRequest(endPoint, param = {}, options) {
  return Communication.postData(endPoint, param, options)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Get request.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} options - Options.
 */
function getUpdateRequest(endPoint, options = {}) {
  return Communication.getData(endPoint, options)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

/**
 * @description - Post request.
 * @param {string} endPoint - Endpont string.
 * @param {Object} param - Param object.
 * @returns {Object} - Data object.
 */
function uploadRequest(endPoint, param = {}) {
  return Communication.postFormData(param, endPoint)
    .then(response => response)
    .catch(err => {
      throw err;
    });
}

/* eslint complexity: ["error", 9] */
/**
 * @description - Fetch Api switch method.
 * @param {string} endPoint - Api endpoints.
 * @param {Object} param - Param.
 * @param {Object} method - Request method.
 * @param {Object} options - Options.
 * @returns {Function} - Corresponding request method.
 */
export const fetchAPI = (endPoint, param, method, options) => {
  switch (method) {
    case httpMethod.GET:
      return getRequest(endPoint, options);
    case httpMethod.POST:
      return postRequest(endPoint, param, options);
    case httpMethod.PUT:
      return putRequest(endPoint, param, options);
    case httpMethod.DOWNLOAD:
      return downloadRequest(endPoint, param, options);
    case httpMethod.POSTUPDATE:
      return postUpdateRequest(endPoint, param, options);
    case httpMethod.GETUPDATE:
      return getUpdateRequest(endPoint, options);
    case httpMethod.FORMPOST:
      return uploadRequest(endPoint, param);
    case httpMethod.FILEUPLOAD:
      return postFileRequest(endPoint, param, options);
    case httpMethod.MULTIFILEUPLOAD:
      return postMultiFileRequest(endPoint, param, options);
    default:
      return getRequest(endPoint);
  }
};

export { fetchAPI as default };
