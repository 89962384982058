import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectSaga } from 'redux-sagas-injector';
import { reloadReducer } from 'redux-reducers-injector';

/**
 * @description - Dynamic import.
 */
class DynamicImport extends Component {
  state = {
    component: null,
  };

  /**
   * @description - Sets reducers, sagas, roles and component.
   * @returns {undefined}
   */
  componentDidMount() {
    const { load } = this.props;
    const loaders = load();

    import(`../../../delta/${loaders.key}/sagas/index.js`).then(saga => {
      // loading sagas

      injectSaga(`roleReducer_saga`, saga.default);

      import(`../../../delta/${loaders.key}/reducer`).then(reducer => {
        // loading reducers

        reloadReducer(`roleReducer`, reducer.default);

        import(`../../../delta/${loaders.key}`).then(component => {
          // finally load components

          this.setState(() => ({
            component: component.default ? component.default : component,
          }));
        });
      });
    });
  }

  /**
   * @description - Render mthod of route import.
   * @returns {Node} - Componnet to render.
   */
  render() {
    const { children } = this.props;
    const { component } = this.state;
    return children(component);
  }
}

DynamicImport.propTypes = {
  // children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  load: PropTypes.func,
};
DynamicImport.defaultProps = {
  // children: null,
  load: null,
};

export default DynamicImport;
