/* eslint complexity: ["error", 4] */
import { LOGOUT, SAVE_TOKEN, TOKEN_NOT_AVAILABLE } from '@core/actions/actionTypes';

const initialState = {
  isToken: false,
  tokenStatus: 0,
  accessToken: '',
  refreshToken: '',
  userLoggedIn: false,
};

/**
 * @description - Token reducer.
 * @param {Object} state - State.
 * @param {Object} action - Action.
 * @returns {Object} - Updated state.
 */
const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKEN:
      return Object.assign({}, state, {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        tokenStatus: action.payload.tokenStatus,
        userLoggedIn: true,
      });
    case LOGOUT.SUCCESS:
      return Object.assign({}, state, {
        accessToken: '',
        refreshToken: '',
        tokenStatus: 0,
        userLoggedIn: false,
        isLoading: true,
      });
    case TOKEN_NOT_AVAILABLE:
      return Object.assign({}, state, {
        tokenStatus: 2,
      });
    default:
      return state;
  }
};
export default tokenReducer;
