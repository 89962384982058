/* eslint-disable max-lines-per-function */
import axios from 'axios';
import { getEndpoint } from '@core/utils/configHelper';
import Validate from '@core/utils/validate';
import FeatureFlag from '@core/utils/featureFlag';
import config from '@core/base/config';
import { setHeader } from '@core/base/auth';

import { logoutToken, logoutSuccess } from '@core/actions';

export default {
  /**
   * @description - Add a response interceptor for multiple requests.
   * @param {Object} store - Store.
   * @returns {Promise} - Promise.
   */
  setupInterceptors: store => {
    // Add a response interceptor
    // for multiple requests
    let isRefreshing = false;
    let failedQueue = [];
    /**
     * @description - Process Queue.
     * @param {string} error - Error.
     * @param {string} token - Token.
     */
    const processQueue = (error, token = null) => {
      failedQueue.forEach(prom => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };

    /**
     * @description - Check for logout.
     * @param {Object} request - Request.
     */
    const checkForLogout = request => {
      if (request.url && request.url.indexOf(config.endpoints.refreshtoken) < 0) {
        store.dispatch(logoutToken(false));
      } else {
        // intercepts the request and check if its a token expiry
        // clears existing tokens and session from browser
        window.localStorage.clear();
        window.sessionStorage.clear(); // code in primeqaint
        // dispatch made to store , so that component can reload to login page
        store.dispatch(logoutSuccess());
      }
    };

    /**
     * @description - Get refresh token.
     * @param {Object} originalRequest - Original Request.
     */
    const getRefreshToken = originalRequest => {
      const refreshToken = window.localStorage.getItem('refreshToken');
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: refreshToken,
        },
      };
      const isMultipersona = FeatureFlag.MULTIPERSONA;
      const { refreshtokenmultipersona, refreshtoken } = config.endpoints;
      const endPoint = isMultipersona ? refreshtokenmultipersona : refreshtoken;
      return new Promise((resolve, reject) => {
        axios
          .get(getEndpoint(endPoint), headers)
          .then(({ data }) => {
            window.localStorage.setItem('accessToken', data.data.accessToken);
            window.localStorage.setItem('refreshToken', data.data.refreshToken);
            // eslint-disable-next-line
            axios.defaults.headers.common['Authorization'] = data.data.accessToken;
            // eslint-disable-next-line
            originalRequest.headers['Authorization'] = data.data.accessToken;
            processQueue(null, data.data.accessToken);
            resolve(axios(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    };

    /**
     * @description - Check Response Error.
     * @param {Object} error - Error status.
     * @param {Object} originalRequest - Original Request.
     * @returns {boolean} - If response is an error or not.
     */
    const checkIfResponseError = (error, originalRequest) => {
      const errorResp = Validate.isExist(error.response, {});
      const errorStatus = Validate.isExist(errorResp.status, '');
      return errorStatus === 401 && !originalRequest._retry; // eslint-disable-line
    };
    // Add a request interceptor
    axios.interceptors.request.use(axiosConfig => setHeader(axiosConfig));

    axios.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config;
        // eslint-disable-next-line
        if (checkIfResponseError(error, originalRequest)) {
          if (isRefreshing) {
            checkForLogout(originalRequest);
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then(token => {
                // eslint-disable-next-line
                originalRequest.headers['Authorization'] = token;
                return axios(originalRequest);
              })
              .catch(err => err);
          }
          // eslint-disable-next-line
          originalRequest._retry = true;
          isRefreshing = true;
          return getRefreshToken(originalRequest);
        }

        return Promise.reject(error);
      },
    );
  },
};
