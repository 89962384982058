/* eslint-disable complexity */
import { takeEvery, call, put } from 'redux-saga/effects';
import fetchAPI from '@core/sagas/fetchAPI';
import { httpMethod } from '@core/enums/httpMethod';
import { GET_REFRESH_TOKEN, GET_REFRESH_TOKEN_FAILURE } from '@core/actions/actionTypes';
import FeatureFlag from '@core/utils/featureFlag';
import { saveToken } from '@core/actions';

/**
 * @description - Get header.
 * @param {Object} tokenObj - Token.
 */
function getHeader(tokenObj) {
  const headers = {
    Authorization: tokenObj.refreshToken,
  };
  return { headers };
}

/**
 * @description - Store token.
 * @param {Object} tokenObj - Token.
 */
function storeToken(tokenObj) {
  localStorage.setItem('accessToken', tokenObj.accessToken);
  localStorage.setItem('refreshToken', tokenObj.refreshToken);
}

/**
 * @description - Get session token.
 * @returns {Object} - Token status, access token, refresh token.
 */
function getSessionToken() {
  return {
    tokenStatus: 1,
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  };
}

/**
 * @description - Get refresh token.
 * @param {Object} action - Action.
 */
function* getRefreshToken(action) {
  const isMultipersona = FeatureFlag.MULTIPERSONA;
  const endPoint = isMultipersona ? 'refreshtokenmultipersona' : 'refreshToken';
  try {
    const res = yield call(fetchAPI, endPoint, {}, httpMethod.GET, getHeader(action.payload));
    if (res.data) {
      storeToken(res.data);
      yield put(saveToken(getSessionToken()));
    } else {
      const errorObj = { message: 'Invalid response' };
      throw errorObj;
    }
  } catch (error) {
    yield put({ type: GET_REFRESH_TOKEN_FAILURE, payload: { message: error } });
  }
}

/**
 * @description - Watches for the refresh token.
 *
 */
export default function* watchRefreshToken() {
  yield takeEvery(GET_REFRESH_TOKEN, getRefreshToken);
}
