import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import './App.scss';
// Router
import { Switch, Route } from 'react-router';
import { BrowserRouter, withRouter, Redirect } from 'react-router-dom';
import Routes from '@core/components/BaseRoute';
import Base from '@core/components/Landing/Base';
import Version from '@core/components/Version';
import Login from '@core/components/Login';
import PrivateRoute from '@core/components/PrivateRoute';
import { connect } from 'react-redux';
import { getUser } from '@core/actions';
import { isUserLoggedIn, listenForToken, checkUserSession } from '@core/base/auth';

/**
 * @description - App components which is the wrapper for the application.
 */
class App extends Component {
  /**
   * @description - Constructor hook to assign state.
   * @param {Object} props - Props.
   */
  constructor(props) {
    super(props);
    this.state = {
      loginStatus: '',
    };
  }

  /**
   * @description - Lifecycle hook.
   * @returns {undefined}
   */
  componentDidMount() {
    // validate refresh token and auth token
    listenForToken().then(resp => {
      this.setState({ loginStatus: resp }, () => {
        if (isUserLoggedIn()) {
          const { dispatch } = this.props;
          dispatch(getUser());
        } else if (resp === 2) {
          this.redirectToLogout();
        }
      });
    });
  }

  /**
   * @description - Function to handle changes.
   * @param {Object} newProps - New inputs.
   * @returns {undefined}
   */
  componentWillReceiveProps(newProps) {
    const { token } = this.props;
    if (checkUserSession(token, newProps)) {
      this.redirectToLogout();
    }
  }

  /**
   * @description - Function to redirectto path.
   * @returns {Node} - Redirect to path.
   */
  redirectToLogout() {
    const { location } = this.props;
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: location,
            errorMessage: 'An error occurred. Please login again',
            mailReqd: false,
          },
        }}
      />
    );
  }

  /**
   * @description - Renders the App component.
   */
  render() {
    const { locale, messages } = this.props;
    const { loginStatus } = this.state;
    return loginStatus !== '' ? (
      <IntlProvider key={locale} locale={locale} messages={messages} textComponent={React.Fragment}>
        <BrowserRouter>
          <Switch>
            <Route path="/version" component={Version} />
            <Route exact path="/" render={props => <Base {...props} tokenStatus={loginStatus} />} />
            <Route path="/login" render={props => <Login {...props} tokenStatus={loginStatus} />} />
            {Routes.map(route => (
              <PrivateRoute
                key={route.path}
                path={route.path}
                component={route.component}
                role={route.role}
              />
            ))}
            <Route component={Login} />
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    ) : null;
  }
}

/**
 * @description - Function which fetches the values returned from the reducer.
 * @param {Object} state - Value obtained from reducer.
 * @returns {Object} - Object containing values from different reducers.
 */
function mapStateToProps(state) {
  return {
    token: state.tokenReducer,
    locale: state.localeReducer.locale,
    messages: state.localeReducer.messages,
  };
}

App.propTypes = {
  location: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.shape({}).isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps)(withRouter(App));
