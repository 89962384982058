/* eslint-disable complexity */
import {
  msalSignIn,
  getAccount,
  listenForTokenRequest,
  getTokenSilent,
  signOut,
} from '@core/base/msalAuth';
import { getEndpoint } from '@core/utils/configHelper';
import config from '@core/base/config';
import FeatureFlag from '@core/utils/featureFlag';

/**
 * @description - Sign in for application.
 * @returns {undefined}
 */
export function signIn() {
  if (FeatureFlag.AZUREOPENID) {
    msalSignIn();
  } else {
    const STR_PRIMEDEV = 'primedev';
    window.location.href =
      getEndpoint(config.endpoints.auth) +
      (process.env.REACT_APP_ENV === STR_PRIMEDEV ? 'local' : '');
  }
}
/**
 * @description - Clears storage.
 * @returns {undefined}
 */
export function clearStorage() {
  if (FeatureFlag.AZUREOPENID) {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('role');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('userRoleId');
    window.localStorage.removeItem('state');
  } else {
    window.localStorage.clear();
  }
}

/**
 * @description - Check user session.
 * @returns {undefined}
 */
function checkUserLoggedIn() {
  const hasRole = window.localStorage.getItem('role');
  if (!FeatureFlag.AZUREOPENID) {
    return (
      window.localStorage.getItem('accessToken') &&
      window.localStorage.getItem('refreshToken') &&
      hasRole
    );
  }

  return hasRole && getAccount() !== null;
}

/**
 * @description - Check user session.
 * @returns {undefined}
 */
export function isUserLoggedIn() {
  return checkUserLoggedIn();
}

/**
 * @description - Check user session.
 * @param {Object} token - Token value from state.
 * @param {Object} newProps - Prev props.
 * @returns {undefined}
 */
export function checkUserSession(token, newProps) {
  if (FeatureFlag.AZUREOPENID) {
    return isUserLoggedIn();
  }

  return token && !newProps.token.isUserLoggedIn;
}

/**
 * @description - Function to check whether user has role or not.
 * @param {string} role - Logged in user's role.
 * @returns {boolean} - True or false.
 */
function getRoleIndex(role) {
  let hasRole = false;
  let roleData = window.localStorage.getItem('role');
  roleData = roleData ? JSON.parse(roleData) : [];
  const roleIndex = roleData && roleData.findIndex(el => el.personaId === role);
  if (roleIndex !== -1) hasRole = true;
  return hasRole;
}

/**
 * @description - Check for role in private route.
 * @param {Object} role - Role name.
 * @returns {undefined}
 */
export function checkUserSessionAndRole(role) {
  const token =
    window.localStorage.getItem('accessToken') && window.localStorage.getItem('refreshToken');
  if (FeatureFlag.AZUREOPENID) {
    return getRoleIndex(role) && getAccount() !== null;
  }
  return token && getRoleIndex(role);
}

/**
 * @description - Alters header with msal token.
 * @param {Object} axiosObj - Header object.
 * @returns {undefined}
 */
function getPersonaScope(axiosObj) {
  const personaList = JSON.parse(localStorage.getItem('role'));
  if (axiosObj.url.indexOf(config.endpoints.getusermultipersona) > -1) {
    return config.scopeInfo.security;
  }
  return personaList && sessionStorage.getItem('selectedUserRole')
    ? [
        personaList.filter(ele => ele.personaId === sessionStorage.getItem('selectedUserRole'))[0]
          .personaScope,
      ]
    : null;
}

/**
 * @description - Alters header with msal token.
 * @param {Object} axiosObj - Header object.
 * @returns {undefined}
 */
export async function setHeader(axiosObj) {
  const axiosConfig = axiosObj;
  if (FeatureFlag.AZUREOPENID) {
    const response = await getTokenSilent(getPersonaScope(axiosObj));
    if (response.error && config.scopeInfo.security === getPersonaScope(axiosObj)) {
      localStorage.setItem('loginError', 'Sorry, your session has expired. Please log in.');
      window.location = '/login';
    }
    axiosConfig.headers.Authorization = response.accessToken;
  }
  return axiosConfig;
}

/**
 * @description - Listen for token request from microsoft.
 * @returns {undefined}
 */
export async function listenForToken() {
  if (FeatureFlag.AZUREOPENID) {
    return listenForTokenRequest();
  }
  return {};
}

/**
 * @description - Get token status to determine redirect after access token validation.
 * @param {Object} doveToken - Token object for non msal.
 * @param {Object} msalTokenStatus - Token object for msal .
 * @returns {undefined}
 */
export function getTokenStatus(doveToken, msalTokenStatus) {
  if (FeatureFlag.AZUREOPENID) {
    return msalTokenStatus;
  }
  return doveToken.tokenStatus;
}

/**
 * @description - Logout.
 * @param {Function} logoutFunction - The logout function to be called.
 * @returns {undefined}
 */
export function applicationLogout(logoutFunction) {
  if (FeatureFlag.AZUREOPENID) {
    signOut();
  } else {
    logoutFunction();
  }
}

/**
 * @description - Logout.
 */
export function showLogin() {
  if (FeatureFlag.AZUREOPENID) {
    window.location.href = '/';
  }
}
