import React, { Component } from 'react';
import { connect } from 'react-redux';
import Landing from '@core/components/Landing/LandingPage';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import colorVariables from '@styles/common/color_variables.scss';
import { signIn, clearStorage } from '@core/base/auth';

/**
 * @description - Login Component.
 */
class Login extends Component {
  /**
   *  @description - to clear exsisting session when login page is loaded.
   */
  componentDidMount() {
    clearStorage();
  }

  /**
   * @description - On login click.
   */
  onLoginClicked = () => {
    signIn();
  };

  /**
   * @description - Function to render error.
   */
  getErrorMessage = () => {
    const { location } = this.props;
    const persistentMessage = localStorage.getItem('loginError');
    const errorMessage = location.state ? location.state.errorMessage : null;
    localStorage.removeItem('loginError');
    return errorMessage || persistentMessage;
  };

  /**
   * @description - Function to render Login component.
   */
  render() {
    const { location } = this.props;
    const errorMsgStyle = {
      padding: '10px',
      color: colorVariables.header_background_color,
    };
    return (
      <React.Fragment>
        <Landing
          btnText="DOVE_GO_LOGIN"
          btnClick={this.onLoginClicked}
          msgText={this.getErrorMessage()}
          mailReqd={location.state ? location.state.mailReqd : false}
          msgStyles={errorMsgStyle}
        />
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};
Login.defaultProps = {
  location: {}, // Gets the current location.
};

export default withRouter(connect()(Login));
