import { reducer as formReducer } from 'redux-form';
import localeReducer from './localeReducer';
import toastReducer from './toastReducer';
import tokenReducer from './tokenReducer';
import userReducer from './userReducer';
import pdbReducer from './pdbReducer';
import personaReducer from './personaReducer';
import commercialPricingAnalystReducer from './commercialPricingAnalystReducer';
import multiSelectDropdownReducer from './multiSelectDropdownReducer';
/**
 * @description - Root reducer.
 * @param {Array} state - State.
 * @returns {Array} - State.
 */
const rootReducer = (state = []) => state;

const coreReducer = {
  localeReducer,
  toastReducer,
  rootReducer,
  tokenReducer,
  userReducer,
  pdbReducer,
  personaReducer,
  commercialPricingAnalystReducer,
  multiSelectDropdownReducer,
  form: formReducer,
};
export default coreReducer;
