import config from '@core/base/config';
/**
 * @description - Check if message is defined or not.
 * @param {Object} data - Data.
 * @param {string} message - Message.
 * @returns {boolean} - Returns message.
 */
function checkMsgUndefined(data, message) {
  return message === undefined ? '' : message;
}

/**
 * @description - Logger.
 */
class Log {
  /**
   * @description - Log error.
   * @param {Object} data - Data.
   * @param {string} message - Message.
   */
  static catchError(data, message) {
    if (config.LOGGER) {
      console.error(data === undefined ? '' : data, checkMsgUndefined(message)); // eslint-disable-line no-console
    }
  }

  /**
   * @description - Log Warning.
   * @param {Object} data - Data.
   * @param {string} message - Message.
   */
  static catchWarn(data, message) {
    if (config.LOGGER) {
      console.warn(data === undefined ? '' : data, checkMsgUndefined(message)); // eslint-disable-line no-console
    }
  }

  /**
   * @description - Log Info.
   * @param {Object} data - Data.
   * @param {string} message - Message.
   */
  static catchInfo(data, message) {
    if (config.LOGGER) {
      console.info(data === undefined ? '' : data, checkMsgUndefined(message)); // eslint-disable-line no-console
    }
  }

  /**
   * @description - Log.
   * @param {Object} data - Data.
   * @param {string} message - Message.
   */
  static catchLog(data, message) {
    if (config.LOGGER) {
      console.log(data === undefined ? '' : data, checkMsgUndefined(message)); // eslint-disable-line no-console
    }
  }
}

export default Log;
