import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getUser } from '@core/actions';
import role from '@core/base/role';
import Landing from '@core/components/Landing/LandingPage';

/**
 * @description - Manage Role component.
 */
class RoleManager extends React.Component {
  /**
   * @description - Constructor.
   * @param {Object} props - Props.
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @description - ComponentDidMount lifecycle.
   */
  componentDidMount() {
    // validate refresh token and auth token
    const { dispatch } = this.props;
    dispatch(getUser());
  }

  /**
   * @description - GetDerivedStateFromProps life cycle.
   * @param {Object} props - Props.
   * @returns {undefined} -  Redirect to login page if the role returned from API does'nt match with the roles available.
   */
  static getDerivedStateFromProps(props) {
    const { user, history } = props;
    /**
     * @description - Check is role is valid.
     * @returns {boolean} - Return true for valid role.
     */
    const isRoleUnavailable = () => user.isValidRole === 0;
    if (user.isValidRole === 1) {
      history.push(role[user.defaultPersona.personaId]);
    }
    // redirect to login page if the role returned from API does'nt match with the roles available
    if (isRoleUnavailable()) {
      history.push({
        pathname: '/login',
        state: {
          from: props.location,
          errorMessage: 'You are not authorized to access this application.',
          mailReqd: true,
        },
      });
    }
    return null;
  }

  /**
   * @description - Function to render component.
   * @returns {Object} - Component.
   */
  render() {
    return (
      <React.Fragment>
        <Landing showLoader />
      </React.Fragment>
    );
  }
}

/**
 * @description - Map state to props function.
 * @param {Object} state - State.
 * @returns {Object} - Updated state.
 */
function mapStateToProps(state) {
  return {
    user: state.userReducer,
  };
}

RoleManager.propTypes = {
  user: PropTypes.shape({}).isRequired,
  token: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps)(RoleManager));
